import $ from 'jquery';
import moment from 'moment';
import Slider from 'bootstrap-slider'; // Import the Slider class
import USGSOverlay from './USGSOverlay.js';
import 'bootstrap-slider/dist/css/bootstrap-slider.css'; // Optional: Import the CSS for the slider
import loadGoogleMapsAPI from 'load-google-maps-api';
import { doChart_forec_timeSeries } from './flot_tseries.js';
import { doChart_forec_densDistrib } from './flot_dens_forec.js';
import { doChart_reana_densDistrib } from './flot_dens_reana.js';
import { leftPad, getJSON, getNetcdf_timestep } from '../utils/utils.js';

// Declare map and bounds as global variables
let map, bounds;
// window.map = null;
// window.bounds = null;

// Global variables
let myLatLng,  marker1, geocoder;
let field4d = [], pathfile_srcDataForec_tevol = [], pathfile_srcDataForec_distr = [], pathfile_srcDataReana_distr = [];
// let field4d = new Array();
// let pathfile_srcDataForec_tevol = new Array();
// let pathfile_srcDataForec_distr = new Array();
// let pathfile_srcDataReana_distr = new Array();

var pathfile_srcImage;
var pathfile_srcLabBar;
var overlay;

var field4d_fillValue
var alpha2d_fillValue
var kappa2d_fillValue
var loc2d_fillValue
var lambda2d_fillValue

var styleIconClass


document.getElementById('swhthreshold').value = 2.0;
var swh_threshold = document.getElementById('swhthreshold')


document.getElementById("map_time").selectedIndex = 0;
// document.getElementById("map_slide").selectedIndex = 1;
document.getElementById("map_region").selectedIndex = 3;
document.getElementById("map_variable").selectedIndex = 0;
document.getElementById("map_depth").selectedIndex = 0;

// Declare variables for map controls
let time = document.getElementById("map_time");
let region = document.getElementById("map_region");
let variable = document.getElementById("map_variable");
let depth = document.getElementById("map_depth");
// slide    = document.getElementById('map_slide');


var mapGetTile = function (x, y, z) {
  return "https://tileserver.maptiler.com/campus/{z}/{x}/{y}.png".replace('{z}', z).replace('{x}', x).replace('{y}', y);
}


export default (function () {
    if (document.getElementById('google-map')) {
        loadGoogleMapsAPI({
            // key: 'AIzaSyDW8td30_gj6sGXjiMU0ALeMu1SDEwUnEA',
            key: 'AIzaSyA_WILB0Vb2nY_m3-N-NdvvbSRNMv7ZvL8',
        }).then(googleMaps => {
            const { google } = window;

            

            var northSeaBounds00 = [52.7,-2.7]
            var northSeaBounds11 = [57.0,8.8]
            var northSeaCenter   = [(northSeaBounds00[0] + northSeaBounds11[0]) / 2.0,
                                    (northSeaBounds00[1] + northSeaBounds11[1]) / 2.0]
            var northSeaBounds00_obj = new googleMaps.LatLng(northSeaBounds00[0],northSeaBounds00[1])
            var northSeaBounds11_obj = new googleMaps.LatLng(northSeaBounds11[0],northSeaBounds11[1])
            var northSeaCenter_obj   = new googleMaps.LatLng(northSeaCenter[0],northSeaCenter[1])

            var iberianSeaBounds00 = [32.6,-9.2]
            var iberianSeaBounds11 = [38.7,0.2]
            var iberianSeaCenter   = [(iberianSeaBounds00[0] + iberianSeaBounds11[0]) / 2.0,
                                        (iberianSeaBounds00[1] + iberianSeaBounds11[1]) / 2.0]
            var iberianSeaBounds00_obj = new googleMaps.LatLng(iberianSeaBounds00[0],iberianSeaBounds00[1])
            var iberianSeaBounds11_obj = new googleMaps.LatLng(iberianSeaBounds11[0],iberianSeaBounds11[1])
            var iberianSeaCenter_obj   = new googleMaps.LatLng(iberianSeaCenter[0],iberianSeaCenter[1])

            var straitBonifBounds00 = [40.5,7.5]
            var straitBonifBounds11 = [42.2,10.5]
            var straitBonifCenter   = [(straitBonifBounds00[0] + straitBonifBounds11[0]) / 2.0,
                                        (straitBonifBounds00[1] + straitBonifBounds11[1]) / 2.0]
            var straitBonifBounds00_obj = new googleMaps.LatLng(straitBonifBounds00[0],straitBonifBounds00[1])
            var straitBonifBounds11_obj = new googleMaps.LatLng(straitBonifBounds11[0],straitBonifBounds11[1])
            var straitBonifCenter_obj   = new googleMaps.LatLng(straitBonifCenter[0],straitBonifCenter[1])

            var ionianSeaBounds00 = [38.5, 16.4]
            var ionianSeaBounds11 = [41.2, 21.0]
            var ionianSeaCenter   = [(ionianSeaBounds00[0] + ionianSeaBounds11[0]) / 2.0,
                                    (ionianSeaBounds00[1] + ionianSeaBounds11[1]) / 2.0]
            var ionianSeaBounds00_obj = new googleMaps.LatLng(ionianSeaBounds00[0],ionianSeaBounds00[1])
            var ionianSeaBounds11_obj = new googleMaps.LatLng(ionianSeaBounds11[0],ionianSeaBounds11[1])
            var ionianSeaCenter_obj   = new googleMaps.LatLng(ionianSeaCenter[0],ionianSeaCenter[1])

            var aegeanSeaBounds00 = [35.8, 22.5]
            var aegeanSeaBounds11 = [41.2, 27.7]
            var aegeanSeaCenter   = [(aegeanSeaBounds00[0] + aegeanSeaBounds11[0]) / 2.0,
                                    (aegeanSeaBounds00[1] + aegeanSeaBounds11[1]) / 2.0]
            var aegeanSeaBounds00_obj = new googleMaps.LatLng(aegeanSeaBounds00[0],aegeanSeaBounds00[1])
            var aegeanSeaBounds11_obj = new googleMaps.LatLng(aegeanSeaBounds11[0],aegeanSeaBounds11[1])
            var aegeanSeaCenter_obj   = new googleMaps.LatLng(aegeanSeaCenter[0],aegeanSeaCenter[1])

            var input_lat = document.getElementById('latitude').value;
            var input_lon = document.getElementById('longitude').value;
            var myLatLng = new google.maps.LatLng(input_lat,input_lon);

            
            // Helper function to convert bounds into LatLng objects
            const convertBounds = (bounds) => ({
                bounds00: new googleMaps.LatLng(bounds[0][0], bounds[0][1]),
                bounds11: new googleMaps.LatLng(bounds[1][0], bounds[1][1]),
                center: new googleMaps.LatLng(bounds[2][0], bounds[2][1]),
            });

            // Generate LatLng objects for each region
            const createLatLngObjects = (region) => {
                const bounds00 = new googleMaps.LatLng(region.bounds[0][0], region.bounds[0][1]);
                const bounds11 = new googleMaps.LatLng(region.bounds[1][0], region.bounds[1][1]);
                const center = new googleMaps.LatLng(region.center[0], region.center[1]);
                return { bounds00, bounds11, center };
            };

            console.log("variable.value="+variable.value);
            console.log("region.value="+region.value);

            // Declare variables before using them
            let varFolder, varFile, varDistrFile_forec, varDistrFile_reana;

            if(variable.value=="swh")      { varFolder="swh",      varFile="WaveHeight",       varDistrFile_forec="WaveHeightDistrib",        varDistrFile_reana="WaveHeightDistribHisto"}
            if(variable.value=="period")   { varFolder="period",   varFile="WavePeriod",       varDistrFile_forec="WavePeriodDistrib",        varDistrFile_reana="WavePeriodDistribHisto"}
            if(variable.value=="curSpeed") { varFolder="curSpeed", varFile="WaterCurrentSpeed",varDistrFile_forec="WaterCurrentSpeedDistrib", varDistrFile_reana="WaterCurrentSpeedDistribHisto"}
            if(variable.value=="tempSea")  { varFolder="tempSea",  varFile="WaterTemperature", varDistrFile_forec="WaterTemperatureDistrib",  varDistrFile_reana="WaterTemperatureDistribHisto"}
            if(variable.value=="windSpeed"){ varFolder="windSpeed",varFile="WindCurrentSpeed", varDistrFile_forec="WindCurrentSpeedDistrib",  varDistrFile_reana="WindCurrentSpeedDistribHisto"}
            if(variable.value=="tempAir")  { varFolder="tempAir",  varFile="AirTemperature",   varDistrFile_forec="AirTemperatureDistrib",    varDistrFile_reana="AirTemperatureDistribHisto"}
            if(variable.value=="mslp")     { varFolder="mslp",     varFile="SeaLevelPressure", varDistrFile_forec="SeaLevelPressureDistrib",  varDistrFile_reana="SeaLevelPressureDistribHisto"}
            if(variable.value=="vis")      { varFolder="vis",      varFile="Visibility",       varDistrFile_forec="VisibilityDistrib",        varDistrFile_reana="VisibilityDistribHisto"}
            if(variable.value=="tcc")      { varFolder="tcc",      varFile="CloudCover",       varDistrFile_forec="CloudCoverDistrib",        varDistrFile_reana="CloudCoverDistribHisto"}
            if(variable.value=="prec")     { varFolder="prec",     varFile="Precipitation",    varDistrFile_forec="PrecipitationDistrib",     varDistrFile_reana="PrecipitationDistribHisto"}


            var mapOptions = {
                zoom: 9,
                center: ionianSeaCenter_obj,
                streetViewControl: false,
                mapTypeId: 'satellite',
                gestureHandling: 'cooperative'  //'greedy',
                // zoomControl: true,
            };

            const map = new google.maps.Map(document.getElementById('google-map'), mapOptions);

            var map_bounds = new google.maps.LatLngBounds();

            google.maps.event.addListener(map, 'bounds_changed', function(){
                var bounds = map.getBounds();
                var NE = bounds.getNorthEast();
                var SW = bounds.getSouthWest();
                //console.log("North East["+NE.lat().toFixed(3)+","+NE.lng().toFixed(3)+"] SouthWest["+NE.lat().toFixed(3)+","+NE.lng().toFixed(3)+"]");
            });

            // Create the marker using standard Google Maps API
            let marker1 = new google.maps.Marker({
                position: myLatLng, // Initial position
                draggable: true,
                map: map // Attach to the map
            });

            if(region.value == "trial01NorthernSea"){
                map.setCenter(northSeaCenter_obj);
                marker1.setPosition(northSeaCenter_obj);
                bounds  = new google.maps.LatLngBounds(northSeaBounds00_obj,northSeaBounds11_obj);
                //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
                map_bounds.extend(northSeaBounds00_obj);
                map_bounds.extend(northSeaBounds11_obj);
                map.fitBounds(map_bounds);
            }
            if(region.value == "trial02IberianSea"){
                map.setCenter(iberianSeaCenter_obj);
                marker1.setPosition(iberianSeaCenter_obj);
                bounds  = new google.maps.LatLngBounds(iberianSeaBounds00_obj,iberianSeaBounds11_obj);
                //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
                map_bounds.extend(iberianSeaBounds00_obj);
                map_bounds.extend(iberianSeaBounds11_obj);
                map.fitBounds(map_bounds);
            }
            if(region.value == "trial03StraitofBonifacio"){
                map.setCenter(straitBonifCenter_obj);
                marker1.setPosition(straitBonifCenter_obj);
                bounds  = new google.maps.LatLngBounds(straitBonifBounds00_obj,straitBonifBounds11_obj);
                //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
                map_bounds.extend(straitBonifBounds00_obj);
                map_bounds.extend(straitBonifBounds11_obj);
                map.fitBounds(map_bounds);
            }
            if(region.value == "trial04IonianSea"){
                map.setCenter(ionianSeaCenter_obj);
                marker1.setPosition(ionianSeaCenter_obj);
                bounds  = new google.maps.LatLngBounds(ionianSeaBounds00_obj,ionianSeaBounds11_obj);
                //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
                map_bounds.extend(ionianSeaBounds00_obj);
                map_bounds.extend(ionianSeaBounds11_obj);
                map.fitBounds(map_bounds);
            }
            if(region.value == "trial05AegeanSea"){
                map.setCenter(aegeanSeaCenter_obj);
                marker1.setPosition(aegeanSeaCenter_obj);
                bounds  = new google.maps.LatLngBounds(aegeanSeaBounds00_obj,aegeanSeaBounds11_obj);
                //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
                map_bounds.extend(aegeanSeaBounds00_obj);
                map_bounds.extend(aegeanSeaBounds11_obj);
                map.fitBounds(map_bounds);
            }

            //---------------------------------------------------
            var contentString = "Drag this marker to the desired location <br>or use the search options on the left."
            var markInfo = {
                content: contentString
            };
            let infowindow = new google.maps.InfoWindow(markInfo);
            infowindow.open(map,marker1);

            //---------------------------------------------------

            geocoder = new google.maps.Geocoder();

            // Geocode and update latitude/longitude fields
            const updateLatLngFields = (latLng) => {
                $('#latitude').val(latLng.lat().toFixed(4));
                $('#longitude').val(latLng.lng().toFixed(4));
            };

            geocoder.geocode({'location': marker1.getPosition()},
                function(results, status) {
                    $('#latitude').val(marker1.getPosition().lat().toFixed(4));
                    $('#longitude').val(marker1.getPosition().lng().toFixed(4));
                });




            let startdateForec = document.getElementById('map_startdateForec');
            console.log("startdateForec="+startdateForec);

            var dropdown_region = region.value;

            var dropdown_startdateForec = document.getElementById('map_startdateForec');
            // console.log("dropdown_startdateForec="+dropdown_startdateForec)
            dropdown_startdateForec.length = 0;
            dropdown_startdateForec.selectedIndex = 0;
            const urlJSON_forec = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/checkData_forec.json"
            console.log("urlJSON_forec="+urlJSON_forec);

            let nfreq;
            if(variable.value == "tempSea" || variable.value == "curSpeed" || variable.value == "swh" || variable.value == "period" ){
                nfreq = 24
            } else {
                nfreq = 4
            }
            console.log("nfreq="+nfreq);

            // Create Google Map Event listener to show markerInfo when the marker1 is clicked
            google.maps.event.addListener(marker1,"click",function() {
                infowindow.close();
                infowindow.open(map,marker1);
            });

            // Create Google Map Event listener to chenge lat,lon when the marker1 is dragend
            google.maps.event.addListener(marker1,"dragend",function() {
                infowindow.close();
                geocoder.geocode({'location': marker1.getPosition()}, function(results, status) {
                    // console.log(status + ' ' + results)
                    $('#latitude').val(marker1.getPosition().lat().toFixed(4));
                    $('#longitude').val(marker1.getPosition().lng().toFixed(4));
                });
            });

            // Load Forcast Data from JSON file.
            getJSON(urlJSON_forec, function(err, data) {

                if (err != null) {
                    console.error(err);
                } else {
                    //console.log("data="+data);
                    for (let i = 0; i < data.length; i++) {
                        let option = document.createElement('option');
                        option.text = data[i].dateStart;
                        //option.value = data[i].dateStart;
                        dropdown_startdateForec.add(option);
                    }
                    console.log("dropdown_startdateForec="+dropdown_startdateForec);
                }

                var dropdown_time = document.getElementById('map_time');
                dropdown_time.length = 0;
                dropdown_time.selectedIndex = 0;

                startdateForec = document.getElementById('map_startdateForec');
                console.log("startdateForec="+startdateForec.value);

                let enddateForec_value = moment(startdateForec.value).add(4, 'days').format('YYYYMMDD');
                console.log("enddateForec_value="+enddateForec_value);

                pathfile_srcDataForec_tevol = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/datasets_marisa/"+varFolder+"/"+region.value+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varFile+".nc"
                console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

                // Load Data from NETCDF file.
                getNetcdf_timestep(pathfile_srcDataForec_tevol, function(err, data) {

                    if (err != null) {
                        console.error(err);
                    } else {
                        // console.log("data1"+data);
                        for (let i = 0; i < data.length; i++) {
                            let option = document.createElement('option');
                            option.text = data[i];
                            option.value = i;
                            dropdown_time.add(option);
                        }
                    }
                    //remove the old slider
                    $(".slider").remove();

                    let idx = 0;
                    let ticksLab = new Array(5);
                    // Generate tick labels based on the data
                    for (let i=0;i<data.length;i++){
                        if (i == idx*nfreq){
                            // Formatting the tick label to display only the first 16 characters of the timestamp
                             ticksLab[idx] = data[idx * nfreq].substr(0, 10);  // This trims to "YYYY-MM-DD HH:mm"
                            // console.log("ticksLab[idx]="+ticksLab[idx]);
                            idx = idx+=1;
                        }
                    }
                    // console.log(ticksLab)

                    var myslider = new Slider("#map_slide", {
                        ticks: [0, (1*nfreq), (2*nfreq), (3*nfreq), (4*nfreq), (5*nfreq)],
                        ticks_labels: [ticksLab[0], ticksLab[1], ticksLab[2], ticksLab[3], ticksLab[4],],
                        ticks_positions: [0, 24/1.07, 48/1.07, 72/1.07, 96/1.07, 107/1.07],
                        step: 1,
                        value: 0,
                        change: function(e) {
                            $(this).val(); // Value on slide end, or when changed programatically.
                        },
                        slide: function(event, ui) {
                            $("#map_slide").val(ui.value);
                            $(ui.value).val($('#map_slide').val());
                        },
                        formatter: function(value) {
                            return '+' + value;
                        }
                    });

                    //---------------------------------------------------

                    if(variable.value == "tempSea" || variable.value == "curSpeed" ){
                        pathfile_srcImage = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/figures/"+varFolder+"/"+startdateForec.value+"/"+varFile+"xy_z"+leftPad(depth.value, 3)+"_t"+leftPad(time.value, 3)+'.png'
                    } else {
                        pathfile_srcImage = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/figures/"+varFolder+"/"+startdateForec.value+"/"+varFile+"xy_t"+leftPad(time.value, 3)+'.png'
                    }
                    // // test -----------------
                    // var prestigeBounds00 = [42.0,-12.5]
                    // var prestigeBounds11 = [44.0,-8.5]
                    // var prestigeBounds00_obj = new google.maps.LatLng(prestigeBounds00[0],prestigeBounds00[1])
                    // var prestigeBounds11_obj = new google.maps.LatLng(prestigeBounds11[0],prestigeBounds11[1])
                    // bounds  = new google.maps.LatLngBounds(prestigeBounds00_obj,prestigeBounds11_obj);
                    // pathfile_srcImage = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/figures/locations_prestige42_44_12.5_8.5.png"
                    // -------------
                    pathfile_srcLabBar = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/figures/"+varFolder+"/"+startdateForec.value+"/labelbar.png"
                    console.log("pathfile_srcImage="+pathfile_srcImage)
                    console.log("pathfile_srcLabBar="+pathfile_srcLabBar);

                    document.getElementById('labelbar').src = pathfile_srcLabBar;

                    // ================================================================
                    // Define the opacity value
                    var opacity = 0.9;
                    var maptiler = new klokantech.MapTilerMapType(map, mapGetTile, bounds);
                    var opacitycontrol = new klokantech.OpacityControl(map, maptiler, opacity);
                    // console.log("opacitycontrol = "+opacitycontrol.opacity)
                    // ================================================================

                    // A custom USGSOverlay object contains the image,the bounds-image and a reference to the map.
                    // overlay = new USGSOverlay(bounds, pathfile_srcImage, map);
                    overlay = new USGSOverlay(google.maps, bounds, pathfile_srcImage, map);

                    // overlay.setOpacity(10);
                    // var opacityDisplay
                    // opacityDisplay.addEventListener('load', opacitycontrol.opacity);

                    //---------------------------------------------------

                    enddateForec_value = moment(startdateForec.value).add(4, 'days').format('YYYYMMDD');
                    // console.log("enddateForec_value="+enddateForec_value);

                    pathfile_srcDataForec_tevol = PATH_SRC_DATA_FOREC_TEVOL+"/"+region.value+"/datasets_marisa/"+varFolder+"/"+region.value+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varFile+".nc"
                    // console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

                    doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile,variable,marker1);

                    //---------------------------------------------------

                    pathfile_srcDataForec_distr = PATH_SRC_DATA_FOREC_DISTR+"/"+region.value+"/"+variable.value+"/"+region.value+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varDistrFile_forec+".nc"
                    // console.log("pathfile_srcDataForec_distr="+pathfile_srcDataForec_distr);

                    doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec,marker1);

                    //---------------------------------------------------
                    //======================
                    // Create Listeners
                    //======================

                    // Create Listeners Index-page
                    document.getElementById('goLocation').addEventListener('click', function() {
                        infowindow.close();
                        geocodeLatLng(geocoder, map, infowindow);
                    });

                    // Create Google Map Event listener when the marker is dragend
                    google.maps.event.addListener(marker1,"dragend",function() {

                        doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile,variable,marker1);
                        doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec,marker1);

                    });

                });  //getNetcdf_timestep(pathfile_srcDataForec_tevol

            });  //getJSON(urlJSON_forec)

            var dropdown_monthlyClim = document.getElementById('map_monthlyClim');
            // console.log("dropdown_monthlyClim="+dropdown_monthlyClim)
            dropdown_monthlyClim.length = 0;
            dropdown_monthlyClim.selectedIndex = 0;
            const urlJSON_reana = PATH_SRC_DATA_REANA_DISTR+"/"+region.value+"/monthlyClimat/checkDataDistr_reana.json"
            console.log("urlJSON_reana="+urlJSON_reana);


            // Load Reana Data from JSON file.
            getJSON(urlJSON_reana, function(err, data) {

                if (err != null) {
                    console.error(err);
                } else {
                    console.log("data.length="+data.length);
                    for (let i = 0; i < data.length; i++) {
                        // console.log("data="+data[i].variable);
                        if(data[i].variable == varDistrFile_reana){
                            // console.log(data[i].items.length)
                            for (let j = 0; j < data[i].items.length; j++) {
                                let option = document.createElement('option');
                                // console.log(data[i].items[j].month)
                                option.text = data[i].items[j].month;
                                //option.value = data[j].month;
                                dropdown_monthlyClim.add(option);
                            }
                            break
                        }
                    }
                }
                
                const monthlyClim = document.getElementById('map_monthlyClim');
                console.log("monthlyClim="+monthlyClim.value);

                const idxMonthForec = Number(startdateForec.value.substring(4,6))
                console.log("idxMonthForec="+idxMonthForec)
                monthlyClim.selectedIndex = idxMonthForec-1

                let startdateReana_value, enddateReana_value;

                if(variable.value == "tempSea" || variable.value == "curSpeed" || variable.value == "swh" || variable.value == "period" ){
                    if(region.value == "trial02IberianSea"){
                        startdateReana_value = '2007'+ leftPad(idxMonthForec, 2)
                        enddateReana_value   = '2011'+ leftPad(idxMonthForec, 2)
                    } else {
                        startdateReana_value = '2007'+ leftPad(idxMonthForec, 2)
                        enddateReana_value   = '2017'+ leftPad(idxMonthForec, 2)
                    }
                } else {
                    startdateReana_value = '2018'+ leftPad(idxMonthForec, 2)
                    enddateReana_value   = '2018'+ leftPad(idxMonthForec, 2)
                }

                pathfile_srcDataReana_distr = PATH_SRC_DATA_REANA_DISTR+"/"+region.value+"/monthlyClimat/"+variable.value+"/"+region.value+"_month"+startdateReana_value+"_"+enddateReana_value+"_"+varDistrFile_reana+".nc"
                // console.log("pathfile_srcDataReana_distr="+pathfile_srcDataReana_distr);

                doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana,monthlyClim,variable,swh_threshold,marker1);

                //---------------------------------------------------
                //======================
                // Create Listeners
                //======================

                // Create Listeners Index-page
                document.getElementById('goLocation').addEventListener('click', function() {
                    geocodeLatLng(geocoder, map, infowindow);
                });

                // Create Event listener when the marker1 is dragend
                document.getElementById("swhthreshold").addEventListener('change', function() {
                    swh_threshold = document.getElementById('swhthreshold');
                    doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana,monthlyClim,variable,swh_threshold,marker1);
                });

                // Create Google Map Event listener when the marker1 is dragend
                google.maps.event.addListener(marker1, 'dragend', function() {
                    doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana,monthlyClim,variable,swh_threshold,marker1);
                });


                // // Create Google Map Event listener when the opacitySlider chenge
                // google.maps.event.addListener(opacitycontrol,'click',function(){
                //     console.log("ciao");
                // });

            });  //getJSON(urlJSON_reana

            // Create Google Map Event listener to change markerColor when the buttom is clicked
            var markerColor = document.getElementById('changeColor');
            google.maps.event.addDomListener(markerColor,"click",function() {
                //pathfile_srcDataReana_distr_swh = PATH_SRC_DATA_REANA_DISTR+"/"+region.value+"/monthlyClimat/swh/"+region.value+"_month"+monthlyClim.value.substring(0,3)+"_WaveHeightDistrib.nc"
                pathfile_srcDataReana_distr_swh = PATH_SRC_DATA_REANA_DISTR+"/"+region.value+"/monthlyClimat/swh/"+region.value+"_month"+startdateReana_value+"_"+enddateReana_value+"_WaveHeightDistribHisto.nc"
                pathfile_srcDataForec_distr_swh = PATH_SRC_DATA_FOREC_DISTR+"/"+region.value+"/swh/"+region.value+"_p"+startdateForec.value+"_"+enddateForec_value+"_WaveHeightDistrib.nc"
                // console.log("pathfile_srcDataReana_distr_swh="+pathfile_srcDataReana_distr_swh);
                // console.log("pathfile_srcDataForec_distr_swh="+pathfile_srcDataForec_distr_swh);
                varDistrFile_reana_swh = "WaveHeightDistribHisto"
                varDistrFile_forec_swh = "WaveHeightDistrib"
                survivalfunction_weibull(pathfile_srcDataReana_distr_swh,pathfile_srcDataForec_distr_swh,varDistrFile_reana_swh,varDistrFile_forec_swh)
            });
        });
    }
}())

// import * as $ from 'jquery';

// Import Bootstrap for frontend components and styling functionality.
import bootstrap from 'bootstrap'; 

// Import global styles (in SCSS format), typically includes Bootstrap and any custom styles.
import '../styles/index.scss'; 

// Constants for data paths
const PATH_SRC_DATA_FOREC_TEVOL = 'products/products_forec_tevol';
const PATH_SRC_DATA_FOREC_DISTR = 'products/products_forec_distr';
const PATH_SRC_DATA_REANA_DISTR = 'products/products_reana_distr';

// Make constants globally accessible
window.PATH_SRC_DATA_FOREC_TEVOL = PATH_SRC_DATA_FOREC_TEVOL;
window.PATH_SRC_DATA_FOREC_DISTR = PATH_SRC_DATA_FOREC_DISTR;
window.PATH_SRC_DATA_REANA_DISTR = PATH_SRC_DATA_REANA_DISTR;

// Import the main Google Maps script to initialize map functionality.
import './googleMaps/index.js'; 

// Import various functions from the `change_timeStepForec.js` file, used to manipulate or update the forecast time steps in the application.
import { 
    change_timeStepForec,         // Handles changing to a specific forecast time step.
    change_timeStepForec_next,    // Handles moving to the next time step.
    change_timeStepForec_preview, // Handles going back to the previous time step.
    change_timeStepForec_slide    // Handles sliding between time steps, often used with a slider UI element.
} from './googleMaps/change_timeStepForec.js';

// Import the `change_variable` function, which allows switching between different meteorological or oceanographic variables (e.g., temperature, wind speed) in the Google Maps overlay.
import {
    change_variable 
} from './googleMaps/change_variable.js'

// Import the `change_region` function to handle updating the region (geographical area) displayed on the Google Maps.
import {
    change_region
} from './googleMaps/change_region.js';


// Make these functions globally available by attaching them to the `window` object.
// This allows them to be called directly from HTML elements or inline scripts (such as `onchange` events).
window.change_timeStepForec = change_timeStepForec;
window.change_timeStepForec_next = change_timeStepForec_next;
window.change_timeStepForec_preview = change_timeStepForec_preview;
window.change_timeStepForec_slide = change_timeStepForec_slide;
window.change_variable = change_variable;
window.change_region = change_region;

import './charts/chartJS/index.js';
import './sidebar/index.js';

// utils.js

import { NetCDFReader } from 'netcdfjs';
import moment from 'moment';

export function leftPad(number, targetLength) {
    var output = number + ''; // Convert the number to a string
    while (output.length < targetLength) {
        output = '0' + output;
    }
    return output;
}



export function getJSON(url, callback) {
    //create a new request object
    let request = new XMLHttpRequest();
    //specify what HTTP request method to use
    request.open('GET', url, true);
    //set the type of response we are expecting
    request.responseType = 'json';
  
    //this is run when the response has returned
    request.onload = function() {
        if(request.readyState === XMLHttpRequest.DONE && request.status === 200) {
            callback(null, request.response);
        } else {
            callback(request.status);
        }
    };
  
    request.send();  //sends the request to the server
};


export function getNetcdf_timestep(url, callback) {

    var time1d  = new Array();
    var date1d = new Array();
  
    //create a new XMLHttpRequest object
    let oReq = new XMLHttpRequest();
  
    //specify what HTTP request method to use
    oReq.open("GET", url,true);
  
    //set the type of response we are expecting (blob for binary data)
    oReq.responseType = "blob";
  
    // Send the request over the network
    oReq.send();
  
    //this is run when the response has returned
    oReq.onload = function () {
        if (oReq.status != 200) { // analyze HTTP status of the response
            // alert(`Error ${oReq.status}: ${oReq.statusText}`); // e.g. 404: Not Found
            console.log("oReq.status"+oReq.status)
            callback(oReq.status, date1d);
        } else { // show the result
        // if(oReq.readyState === XMLHttpRequest.DONE && oReq.status === 200) {
            var blob = oReq.response;
  
            let reader_url = new FileReader();
  
            reader_url.onload = function() {
                var t0 = performance.now();
  
                // reader = new netcdfjs(this.result);
                const reader = new NetCDFReader(this.result);
                //console.log(reader);
  
                //-------------------------
                //Define Time and his units
                //-------------------------
                var time1d = reader.getDataVariable('time');
                // console.log(time1d);
  
                var variables= reader.header.variables;
                var variabTime_units;
                //console.log(variables);
                var idx = 0;
                do {
                  if(variables[idx].name == "time"){
                    //console.log("variables["+idx+"].name="+variables[idx].name);
                    var variabTime = variables[idx].attributes;
                    var idx_t = 0;
                    do {
                      if(variabTime[idx_t].name == "units"){
                        //console.log("variabTime["+idx_t+"].name="+variabTime[idx_t].name);
                        variabTime_units  = variabTime[idx_t].value.replace("hours since ", "");
                        variabTime_units  = variabTime[idx_t].value.replace("seconds since ", "");
                        //console.log("variabTime_units="+variabTime_units);
                      }
                      idx_t += 1;
                    } while (idx_t < variabTime.length);
  
                  }
                  idx += 1;
                } while (idx < variables.length);
                var dateRef = new Date(variabTime_units);
                //console.log("dateRef="+dateRef);
  
                // var date1d = new Array();
                for (var it = 0; it < time1d.length; it++) {
                    date1d[it] = moment(dateRef).add(time1d[it], 'seconds').format('YYYY-MM-DD HH:mm:ss');
                    //date1d[it] = moment(dateRef).add(time1d[it], 'hours').format('YYYY-MM-DD hh:mm:ss');
                    //console.log("date1d[" +it+"]="+time1d[it]+" "+date1d[it]);
                }
                // console.log(date1d);
                callback(null, date1d);
  
            }
            //console.log(blob);
            reader_url.readAsArrayBuffer(blob);
  
        // } else {
        //     callback(oReq.status);
        }
  
    };
  
}

  

export function closest(array,num){
    var i=0;
    var minDiff=1000;
    var ans;
    for(i in array){
         var m=Math.abs(num-array[i]);
         if(m<minDiff){
                minDiff=m;
                ans=i;
        }
    }
    return ans;
}


export function calcIdxVar2d(varMultiD,lat,lon,idxVar,marker1) {

    //console.log("ndims3D=["+lat.length+","+lon.length+"] = "+
    //            (lat.length*lon.length));
    let lat_marker = marker1.getPosition().lat().toFixed(4);
    let lgn_marker = marker1.getPosition().lng().toFixed(4);
    //console.log(lon);
    //console.log(lat);
    let ilgn_marker = closest(lon,lgn_marker);
    let ilat_marker = closest(lat,lat_marker);
    //console.log(lgn_marker+"  "+lat_marker);
    //console.log("lon["+ilgn_marker+"]="+lon[ilgn_marker]+"  lat["+ilat_marker+"]="+lat[ilat_marker]);

    //var idxVar = Array(time.length);
    for (let ilat = 0; ilat < lat.length; ilat++){
        for (let ilon = 0; ilon < lon.length; ilon++){
            // idx=(lon.length*ilat)+ilon;
            if((ilon == ilgn_marker) && (ilat == ilat_marker)){
                idxVar[0] = (lon.length*ilat)+ilon;
                // console.log(ilon+" "+ilat+" "+idxVar[0]+"  "+varMultiD[idxVar[0]]);
            }
        }
    }
    // console.log(idxVar);
}


export function calcIdxVar3d(varMultiD,time,lat,lon,idxVar,marker1) {

    //console.log("ndims3D=["+time.length+","+lat.length+","+lon.length+"] = "+
    //            (time.length*lat.length*lon.length));
    let lat_marker = marker1.getPosition().lat().toFixed(4);
    let lgn_marker = marker1.getPosition().lng().toFixed(4);
    //console.log(lon);
    //console.log(lat);
    let ilgn_marker = closest(lon,lgn_marker);
    let ilat_marker = closest(lat,lat_marker);
    //console.log(lgn_marker+"  "+lat_marker);
    //console.log("lon["+ilgn_marker+"]="+lon[ilgn_marker]+"  lat["+ilat_marker+"]="+lat[ilat_marker]);

    //var idxVar = Array(time.length);
    for (let itime = 0; itime < time.length; itime++){

        for (let ilat = 0; ilat < lat.length; ilat++){
            for (let ilon = 0; ilon < lon.length; ilon++){
                let idx=(lon.length*ilat)+ilon;
                if((ilon == ilgn_marker) && (ilat == ilat_marker)){
                    idxVar[itime] = (lon.length*lat.length*itime)+idx;
                    //console.log(itime+" "+ilon+" "+ilat+" "+idxVar[itime]+"  "+varMultiD[idxVar[itime]]);
                }
            }
        }
    }
    //console.log(idxVar);
    
}


export function calcIdxVar4d(varMultiD,time,depth,lat,lon,idxVar,render,marker1) {

    console.log("ndims4D=["+time.length+","+depth.length+","+lat.length+","+lon.length+"] = "+
                (time.length*depth.length*lat.length*lon.length));
                let lat_marker = marker1.getPosition().lat().toFixed(4);
                let lgn_marker = marker1.getPosition().lng().toFixed(4);
    //console.log(lon);
    //console.log(lat);
    let ilgn_marker = closest(lon,lgn_marker);
    let ilat_marker = closest(lat,lat_marker);
    //console.log("lgn_marker="+lgn_marker+"  lat_marker="+lat_marker);
    //console.log("lon["+ilgn_marker+"]="+lon[ilgn_marker]+"  lat["+ilat_marker+"]="+lat[ilat_marker]);

    //var idxVar = Array(time.length);
    for (let itime = 0; itime < time.length; itime++){

        for (let ilat = 0; ilat < lat.length; ilat++){
            for (let ilon = 0; ilon < lon.length; ilon++){
                let idx=(lon.length*ilat)+ilon;
                if((ilon == ilgn_marker) && (ilat == ilat_marker)){
                    idxVar[itime] = (lon.length*lat.length*itime)+idx;
                    //console.log(itime+" "+ilon+" "+ilat+" "+idxVar[itime]+"  "+varMultiD[idxVar[itime]]);
                }
            }
        }
    }
    //console.log(idxVar);
}


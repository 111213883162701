// import * as $ from 'jquery';
import $ from 'jquery';

export default (function () {
  // Handle Sidebar Links Toggle
  const toggleSidebarLink = function () {
    const $link = $(this);
    const $parentLi = $link.parent();
    const $dropdownMenu = $parentLi.children('.dropdown-menu');

    if ($parentLi.hasClass('open')) {
      $dropdownMenu.slideUp(200, () => {
        $parentLi.removeClass('open');
      });
    } else {
      $dropdownMenu.slideDown(200, () => {
        $parentLi.addClass('open');
      });
    }
  };

  // Attach event handler to sidebar links
  $('.sidebar .sidebar-menu li a').on('click', toggleSidebarLink);

  // Set Active Sidebar Link
  const setActiveSidebarLink = function () {
    const currentPath = window.location.pathname.substr(1); // Remove leading slash

    $('.sidebar .sidebar-link').each((_, el) => {
      const $el = $(el);
      const href = $el.attr('href') || '';
      const pattern = href.startsWith('/') ? href.substr(1) : href;

      $el.toggleClass('active', pattern === currentPath);
    });
  };

  setActiveSidebarLink();

  // Handle Sidebar Collapse Toggle
  $('.sidebar-toggle').on('click', (e) => {
    e.preventDefault();
    $('.app').toggleClass('is-collapsed');
  });

  // Handle Sidebar Toggle with Window Resize Event
  $('#sidebar-toggle').on('click', (e) => {
    e.preventDefault();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  });
}());




// export default (function () {
//   // Handle Sidebar Links Toggle
//   const toggleSidebarLink = function () {
//     const $link = $(this);
//     const $parentLi = $link.parent();
//     const $dropdownMenu = $parentLi.children('.dropdown-menu');
//     const $openItems = $parentLi.siblings('.open');

//     if ($parentLi.hasClass('open')) {
//       $dropdownMenu.slideUp(200, () => {
//         $parentLi.removeClass('open');
//       });
//     } else {
//       $openItems.removeClass('open').children('.dropdown-menu').slideUp(200);
//       $dropdownMenu.slideDown(200, () => {
//         $parentLi.addClass('open');
//       });
//     }
//   };

//   // Attach event handler to sidebar links
//   $('.sidebar .sidebar-menu li a').on('click', toggleSidebarLink);

//   // Set Active Sidebar Link
//   const setActiveSidebarLink = function () {
//     const currentPath = window.location.pathname.substr(1); // Remove leading slash

//     $('.sidebar .sidebar-link').each((_, el) => {
//       const $el = $(el);
//       const href = $el.attr('href') || '';
//       const pattern = href.startsWith('/') ? href.substr(1) : href;

//       if (pattern === currentPath) {
//         $el.addClass('active');
//       } else {
//         $el.removeClass('active');
//       }
//     });
//   };

//   setActiveSidebarLink();

//   // Handle Sidebar Toggle
//   $('.sidebar-toggle').on('click', (e) => {
//     e.preventDefault();
//     $('.app').toggleClass('is-collapsed');
//   });

//   // Handle Sidebar Toggle with Window Resize Event
//   $('#sidebar-toggle').on('click', (e) => {
//     e.preventDefault();
//     setTimeout(() => {
//       window.dispatchEvent(new Event('resize'));
//     }, 300);
//   });
// }());

import $ from 'jquery';
import 'flot';
import { NetCDFReader } from 'netcdfjs';
import { calcIdxVar3d, calcIdxVar4d } from '../utils/utils.js';
import moment from 'moment';

// Function to set chart options
function getChartOptions() {
    return {
        series: {
            lines: { show: true },
            points: { radius: 2, fill: true, show: true }
        },
        xaxis: {
            mode: "time",
            position: 'bottom',
            axisLabel: 'Date',
            font: { size: 18, style: "italic", color: "black" }
        },
        yaxis: {
            position: 'left',
            axisLabel: '',
            font: { size: 18, style: "italic", color: "black" }
        },
        // legend: {
        //     show: true,
        //     noColumns: 1,
        //     margin: 10,
        //     fontSize: 20,
        //     labelBoxBorderColor: "black",
        //     position: "ne"
        // },
        grid: {
            hoverable: true,
            borderWidth: 2,
            borderColor: "black",
            backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        tooltip: true,
        tooltipOpts: {
            position: 'absolute',
            display: 'none',
            border: '1px solid #633200',
            padding: '3px',
            'font-size': '19px',
            'border-radius': '6px',
            'background-color': '#fff',
            'font-family': 'Verdana, Arial, Helvetica, Tahoma, sans-serif',
            opacity: 0.9
        },
        // Add legend configuration
        legend: {
            show: true,                   // Display the legend
            noColumns: 1,                 // Number of columns in the legend
            labelBoxBorderColor: "red",   // Border color of the label box
            position: "ne",               // Legend position (n = north, e = east)
            margin: 10,                   // Margin around the legend
            backgroundOpacity: 0.5,       // Ensure the background is fully opaque
            backgroundColor: "red"        // Set background color to white
        }
    };
}

// Initialize empty chart data
function initializeChartData() {
    return [
        {
            color: "red",
            yaxis: 1
        }
    ];
}

// Plot chart with given data and options
function plotChart(chartData, chartOptions) {

    const plotContainer = $("#flot-line-chart-multi");
    const parentWidth = plotContainer.parent().width(); // Get the parent div's width
    plotContainer.width(parentWidth); // Set the plot container's width to match the parent
    $.plot(plotContainer, chartData, chartOptions);
    
}



export function doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile,variable,marker1) {

    // Ensure the chart area is empty before rendering a new chart
    $(document).ready(() => {
        $("#flot-line-chart-multi").empty();
    });
    
    const chartOptions = getChartOptions();
    const chartData = initializeChartData();
    const time1d = [];
    const date1d = [];
    const field1d = [];

    
    //create a new XMLHttpRequest object
    let oReq = new XMLHttpRequest();
    //specify what HTTP request method to use
    oReq.open("GET", pathfile_srcDataForec_tevol,true);
    //set the type of response we are expecting (blob for binary data)
    oReq.responseType = "blob";
    // Send the request over the network
    oReq.send();

    //this is run when the response has returned
    oReq.onload = function () {
        if (oReq.status !== 200) {
            console.error(`Error ${oReq.status}: ${oReq.statusText}`);
            plotChart(chartData, chartOptions);
            return;
        }

        const reader_url = new FileReader();
        reader_url.onload = function () {
            const t0 = performance.now();

            const reader = new NetCDFReader(this.result);
            //console.log(reader);

            const lat1d = reader.getDataVariable('latitude');
            const lon1d = reader.getDataVariable('longitude');
            const depth1d = (variable.value === "tempSea" || variable.value === "curSpeed") ? reader.getDataVariable('depth') : null;
            // if(variable.value == "tempSea" || variable.value == "curSpeed" ){
            //     var depth1d = reader.getDataVariable('depth');
            // }
            var variables = reader.header.variables;
            let variabTime_units = '';
            var time1d = reader.getDataVariable('time');
            //console.log(time1d);

            var idx = 0;
            do {
                if(variables[idx].name == "time"){
                    //console.log("variables["+idx+"].name="+variables[idx].name);
                    var variabTime  = variables[idx].attributes;
                    //console.log(variabTime);
                    var idx_t = 0;
                    do {
                        if(variabTime[idx_t].name == "units"){
                        //console.log("variabTime["+idx_t+"].name="+variabTime[idx_t].name);
                        //console.log("variabTime["+idx_t+"].value="+variabTime[idx_t].value);
                        //variabTime_units = variabTime[idx_t].value.replace("hours since ", "");;
                        variabTime_units = variabTime[idx_t].value.replace("seconds since ", "");;
                        //console.log("variabTime_units="+variabTime_units);
                    }
                    idx_t += 1;
                    } while (idx_t < variabTime.length);
                }
                idx += 1;
            } while (idx < variables.length);
            var dateRef = new Date(variabTime_units);
            //console.log("dateRef="+dateRef);

            for (let it = 0; it < time1d.length; it++) {
                //date1d[it] = moment(dateRef).add(time1d[it], 'hours').toDate();
                date1d[it] = moment(dateRef).add(time1d[it], 'seconds').toDate();
                //console.log("date1d[" +it+"]="+date1d[it]);
            }
            //console.log(date1d);

            //-------------------------
            //Define Variable and his units
            //-------------------------
            //ncap2 -s 'votemper = votemper.float()' trial01NorthernSea_p20180418_votemper.nc out.nc
            //var scale = 0.001
            //var offset = 25.
            //field4d_float = scale*(field4d_short - offset)
            var field4d = reader.getDataVariable(varFile); // go to offset and read it
            //console.log(field4d);
            let field4d_longName = '';
            let field4d_units = '';
            let field4d_fillValue = '';

            var idx = 0;
            do {
                if(variables[idx].name == varFile){
                //console.log("variables["+idx+"].name="+variables[idx].name);
                var attributes  = variables[idx].attributes;
                // console.log(attributes);
                var idx_var = 0;
                do {
                    if(attributes[idx_var].name == "long_name"){
                        field4d_longName  = attributes[idx_var].value;
                        //console.log("field4d_longName="+field4d_longName);
                    }
                    if(attributes[idx_var].name == "units"){
                        field4d_units  = attributes[idx_var].value;
                        // console.log("field4d_units="+field4d_units);
                    }
                    if(attributes[idx_var].name == "_FillValue"){
                        field4d_fillValue  = attributes[idx_var].value;
                        //console.log("field4d_fillValue="+field4d_fillValue);
                    }
                    idx_var += 1;
                } while (idx_var < attributes.length);
                }
                idx += 1;
            } while (idx < variables.length);
            
            //console.log("field4d_longName="+field4d_longName);
            //console.log("field4d_units="+field4d_units);
            //console.log("field4d_fillValue="+field4d_fillValue);

            var t1 = performance.now();
            //console.log("Decoding took " + (t1 - t0) + " milliseconds.");
        
            //var field1d = new Array();
            var idxVar = Array(time1d.length);
            if(variable.value == "tempSea" || variable.value == "curSpeed" ){
                calcIdxVar4d(field4d,time1d,depth1d,lat1d,lon1d,idxVar,marker1);
            } else {
                calcIdxVar3d(field4d,time1d,lat1d,lon1d,idxVar,marker1);
            }
            //console.log("idxVar="+idxVar);

            for (let i = 0; i < idxVar.length; i++){
                if (field4d[idxVar[i]] == field4d_fillValue) {
                    field1d[i] = [date1d[i],undefined];
                }else {
                    field1d[i] = [date1d[i],field4d[idxVar[i]]];
                }
                //console.log(field4d[idxVar[i]]);
            }

            //time1d = time1d.concat(time1d_sub);
            //console.log(time1d);

            //field1d = field1d.concat(field1d_sub);
            //console.log(field1d);
            let xyLabel = field4d_longName.replace('(Tp)','').replace('(Hm0)','')+" ["+field4d_units+"]";
            chartData[0].data  = field1d; 
            chartData[0].label = xyLabel;
            chartOptions.yaxis.axisLabel = xyLabel;
            chartOptions.tooltipOpts.content="<center>  %y.2 ["+field4d_units+"] <br> %x </center>";
            //chartOptions.tooltipOpts.content="<center><i><b>Hello <br> Joe!</b></i></center>";
            //console.log(chartData)

            plotChart(chartData, chartOptions);

        }
        //console.log(blob);
        reader_url.readAsArrayBuffer(oReq.response);

    };

    oReq.onerror = function() { // only triggers if the request couldn't be made at all
        alert(`Network Error`);
    };

    // Replot the chart when the window is resized to adapt to the new width
    $(window).resize(() => {
        plotChart(chartData, chartOptions);
    });

}

import $ from 'jquery';
import moment from 'moment';
import Slider from 'bootstrap-slider';
import USGSOverlay from './USGSOverlay.js';
import 'bootstrap-slider/dist/css/bootstrap-slider.css'; // Optional: Import the CSS for the slider
import { doChart_forec_timeSeries } from './flot_tseries.js';
import { doChart_forec_densDistrib } from './flot_dens_forec.js';
import { doChart_reana_densDistrib } from './flot_dens_reana.js';
import { leftPad, getJSON, getNetcdf_timestep } from '../utils/utils.js';

// // Define LatLng coordinates for various regions
// const ionianSeaCenter_obj = new google.maps.LatLng(39.85, 18.7);
// const northSeaCenter_obj = new google.maps.LatLng(54.85, 3.05);
// const aegeanSeaCenter_obj = new google.maps.LatLng(38.5, 25.1);

// // Define bounds for various regions
// const northSeaBounds00_obj = new google.maps.LatLng(52.7, -2.7);
// const northSeaBounds11_obj = new google.maps.LatLng(57.0, 8.8);
// const ionianSeaBounds00_obj = new google.maps.LatLng(38.5, 16.4);
// const ionianSeaBounds11_obj = new google.maps.LatLng(41.2, 21.0);
// const aegeanSeaBounds00_obj = new google.maps.LatLng(35.8, 22.5);
// const aegeanSeaBounds11_obj = new google.maps.LatLng(41.2, 27.7);

// Global variable definition
const path_srcDataForecTevol = 'products/products_forec_tevol';
let pathfile_srcImage, pathfile_srcLabBar, overlay, bounds, map;
let varFolder, varFile, varDistrFile_forec, varDistrFile_reana;

// Mapping of variable values to folder and file names
const variableMappings = {
    swh: { folder: 'swh', file: 'WaveHeight', forecFile: 'WaveHeightDistrib', reanaFile: 'WaveHeightDistribHisto' },
    period: { folder: 'period', file: 'WavePeriod', forecFile: 'WavePeriodDistrib', reanaFile: 'WavePeriodDistribHisto' },
    curSpeed: { folder: 'curSpeed', file: 'WaterCurrentSpeed', forecFile: 'WaterCurrentSpeedDistrib', reanaFile: 'WaterCurrentSpeedDistribHisto' },
    tempSea: { folder: 'tempSea', file: 'WaterTemperature', forecFile: 'WaterTemperatureDistrib', reanaFile: 'WaterTemperatureDistribHisto' },
    windSpeed: { folder: 'windSpeed', file: 'WindCurrentSpeed', forecFile: 'WindCurrentSpeedDistrib', reanaFile: 'WindCurrentSpeedDistribHisto' },
    tempAir: { folder: 'tempAir', file: 'AirTemperature', forecFile: 'AirTemperatureDistrib', reanaFile: 'AirTemperatureDistribHisto' },
    mslp: { folder: 'mslp', file: 'SeaLevelPressure', forecFile: 'SeaLevelPressureDistrib', reanaFile: 'SeaLevelPressureDistribHisto' },
    vis: { folder: 'vis', file: 'Visibility', forecFile: 'VisibilityDistrib', reanaFile: 'VisibilityDistribHisto' },
    tcc: { folder: 'tcc', file: 'CloudCover', forecFile: 'CloudCoverDistrib', reanaFile: 'CloudCoverDistribHisto' },
    prec: { folder: 'prec', file: 'Precipitation', forecFile: 'PrecipitationDistrib', reanaFile: 'PrecipitationDistribHisto' }
};

export function change_region(monthlyClim_value,startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue) {

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        // console.log("overlay"+overlay);
        overlay.setMap(null);
    }

    // Assign variable details from mappings
    const variableDetails = variableMappings[variablevalue];
    if (!variableDetails) {
        console.error(`Variable ${variablevalue} is not recognized.`);
        return;
    }

    varFolder = variableDetails.folder;
    varFile = variableDetails.file;
    varDistrFile_forec = variableDetails.forecFile;
    varDistrFile_reana = variableDetails.reanaFile;

    console.log(`Processing forecast for variable: ${variablevalue}, region: ${regionvalue}`);

    // Initialize map
    var mapOptions = {
        zoom: 9,
        center: ionianSeaCenter_obj,
        streetViewControl: false,
        mapTypeId: 'satellite',
        gestureHandling: 'cooperative'  //'greedy',
        //zoomControl: true,
    };
    map = new google.maps.Map(document.getElementById('map'), mapOptions);

    var map_bounds = new google.maps.LatLngBounds();
    //map_bounds.extend(ionianSeaBounds00_obj);
    //map_bounds.extend(ionianSeaBounds11_obj);
    //map.fitBounds(map_bounds);

    google.maps.event.addListener(map, 'bounds_changed', function(){
        var bounds = map.getBounds();
        var NE = bounds.getNorthEast();
        var SW = bounds.getSouthWest();
        //console.log("North East["+NE.lat().toFixed(3)+","+NE.lng().toFixed(3)+"] SouthWest["+NE.lat().toFixed(3)+","+NE.lng().toFixed(3)+"]");
    });

    //bounds = new google.maps.LatLngBounds(ionianSeaBounds00_obj,ionianSeaBounds11_obj);

    //---------------------------------------------------
    //Green(#00ff00), Red(#ff0000), Blue(#0000ff)
    var styleIconClass = new StyledIcon(StyledIconTypes.CLASS,{color:"#0000ff"}); //Green(#00ff00), Red(#ff0000), Blue(#0000ff)
    var markOptions1 = {
        styleIcon:new StyledIcon(StyledIconTypes.MARKER,{text:""},styleIconClass),
        position:myLatLng,
        draggable: true,
        map: map
    };
    marker1 = new StyledMarker(markOptions1);

    if(regionvalue == "trial01NorthernSea"){
        map.setCenter(northSeaCenter_obj);
        marker1.setPosition(northSeaCenter_obj);
        bounds  = new google.maps.LatLngBounds(northSeaBounds00_obj,northSeaBounds11_obj);
        //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
        map_bounds.extend(northSeaBounds00_obj);
        map_bounds.extend(northSeaBounds11_obj);
        map.fitBounds(map_bounds);
    }
    if(regionvalue == "trial02IberianSea"){
        map.setCenter(iberianSeaCenter_obj);
        marker1.setPosition(iberianSeaCenter_obj);
        bounds  = new google.maps.LatLngBounds(iberianSeaBounds00_obj,iberianSeaBounds11_obj);
        //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
        map_bounds.extend(iberianSeaBounds00_obj);
        map_bounds.extend(iberianSeaBounds11_obj);
        map.fitBounds(map_bounds);
    }
    if(regionvalue == "trial03StraitofBonifacio"){
        map.setCenter(straitBonifCenter_obj);
        marker1.setPosition(straitBonifCenter_obj);
        bounds  = new google.maps.LatLngBounds(straitBonifBounds00_obj,straitBonifBounds11_obj);
        //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
        map_bounds.extend(straitBonifBounds00_obj);
        map_bounds.extend(straitBonifBounds11_obj);
        map.fitBounds(map_bounds);
    }
    if(regionvalue == "trial04IonianSea"){
        map.setCenter(ionianSeaCenter_obj);
        marker1.setPosition(ionianSeaCenter_obj);
        bounds  = new google.maps.LatLngBounds(ionianSeaBounds00_obj,ionianSeaBounds11_obj);
        //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
        map_bounds.extend(ionianSeaBounds00_obj);
        map_bounds.extend(ionianSeaBounds11_obj);
        map.fitBounds(map_bounds);
    }
    if(regionvalue == "trial05AegeanSea"){
        map.setCenter(aegeanSeaCenter_obj);
        marker1.setPosition(aegeanSeaCenter_obj);
        bounds  = new google.maps.LatLngBounds(aegeanSeaBounds00_obj,aegeanSeaBounds11_obj);
        //map_bounds = new google.maps.LatLngBounds(null); // resets the current bounds and allows the new call for zoom in/out
        map_bounds.extend(aegeanSeaBounds00_obj);
        map_bounds.extend(aegeanSeaBounds11_obj);
        map.fitBounds(map_bounds);
    }

    $('#latitude').val(marker1.getPosition().lat().toFixed(4));
    $('#longitude').val(marker1.getPosition().lng().toFixed(4));

    var dropdown_region = regionvalue;

    var dropdown_startdateForec = document.getElementById('map_startdateForec');
    // console.log("dropdown_startdateForec="+dropdown_startdateForec)
    dropdown_startdateForec.length = 0;
    dropdown_startdateForec.selectedIndex = 0;
    const urlJSON_forec = path_srcDataForecTevol+"/"+regionvalue+"/checkData_forec.json"
    // console.log("urlJSON_forec="+urlJSON_forec);

    let nfreq;
    if(variablevalue == "tempSea" || variablevalue == "curSpeed" || variablevalue == "swh" || variablevalue == "period" ){
        nfreq = 24
    } else {
        nfreq = 4
    }

    // Create Google Map Event listener to show markerInfo when the marker1 is clicked
    google.maps.event.addListener(marker1,"click",function() {
      infowindow.open(map,marker1);
    });

    // Create Google Map Event listener to chenge lat,lon when the marker1 is dragend
    google.maps.event.addListener(marker1,"dragend",function() {

        // styleIconClass.set("color","#0000ff"); //Green(#00ff00), Red(#ff0000), Blue(#0000ff)
        infowindow.close();

        geocoder.geocode({'location': marker1.getPosition()}, function(results, status) {
            // console.log(status + ' ' + results)
            $('#latitude').val(marker1.getPosition().lat().toFixed(4));
            $('#longitude').val(marker1.getPosition().lng().toFixed(4));
        });

    });

    // Load Forcast Data from JSON file.
    getJSON(urlJSON_forec, function(err, data) {

        if (err != null) {
            console.error(err);
        } else {
            //console.log("data="+data);
            for (let i = 0; i < data.length; i++) {
                option = document.createElement('option');
                option.text = data[i].dateStart;
                //option.value = data[i].dateStart;
                dropdown_startdateForec.add(option);
            }
            console.log("dropdown_startdateForec="+dropdown_startdateForec);
        }

        var dropdown_time = document.getElementById('map_time');
        dropdown_time.length = 0;
        dropdown_time.selectedIndex = 0;

        startdateForec = document.getElementById('map_startdateForec');
        console.log("startdateForec="+startdateForec.value);
        enddateForec_value = moment(startdateForec.value).add(4, 'days').format('YYYYMMDD');
        console.log("enddateForec_value="+enddateForec_value);

        pathfile_srcDataForec_tevol = path_srcDataForecTevol+"/"+regionvalue+"/datasets_marisa/"+varFolder+"/"+regionvalue+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varFile+".nc"
        // console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

        // Load Data from NETCDF file.
        getNetcdf_timestep(pathfile_srcDataForec_tevol, function(err, data) {

            if (err != null) {
                console.error(err);
            } else {
                //console.log(data);
                for (let i = 0; i < data.length; i++) {
                    option = document.createElement('option');
                    option.text = data[i];
                    option.value = i;
                    dropdown_time.add(option);
                }
            }
            //remove the old slider
            $(".slider").remove();

            idx = 0;
            var ticksLab = new Array(5);
            for (i=0;i<data.length;i++){
                if (i == idx*nfreq){
                    ticksLab[idx] = data[idx*nfreq].substr(0,16);
                    console.log("ticksLab[idx]="+ticksLab[idx]);
                    idx = idx+=1;
                }
            }
            // console.log(ticksLab)

            var myslider = new Slider("#map_slide", {
                ticks: [0, (1*nfreq), (2*nfreq), (3*nfreq), (4*nfreq), (5*nfreq)],
                ticks_labels: [ticksLab[0], ticksLab[1], ticksLab[2], ticksLab[3], ticksLab[4],],
                ticks_positions: [0, 24/1.07, 48/1.07, 72/1.07, 96/1.07, 107/1.07],
                step: 1,
                value: 0,
                change: function(e) {
                    $(this).val(); // Value on slide end, or when changed programatically.
                },
                slide: function(event, ui) {
                    $("#map_slide").val(ui.value);
                    $(ui.value).val($('#map_slide').val());
                },
                formatter: function(value) {
                    return '+' + value;
                }
            });

            //---------------------------------------------------

            if(variablevalue == "tempSea" || variablevalue == "curSpeed" ){
                pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec.value+"/"+varFile+"xy_z"+leftPad(depthvalue, 3)+"_t"+leftPad(timevalue, 3)+'.png'
            } else {
                pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec.value+"/"+varFile+"xy_t"+leftPad(timevalue, 3)+'.png'
            }
            pathfile_srcLabBar = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec.value+"/labelbar.png"
            //console.log("pathfile_srcImage="+pathfile_srcImage)
            //console.log("pathfile_srcLabBar="+pathfile_srcLabBar);

            document.getElementById('labelbar').src = pathfile_srcLabBar;

            // ================================================================
            opacity = 0.9
            var maptiler = new klokantech.MapTilerMapType(map, mapGetTile, bounds);
            var opacitycontrol = new klokantech.OpacityControl(map, maptiler, opacity);
            console.log("opacitycontrol = "+opacitycontrol.opacity)
            // ================================================================

            // A custom USGSOverlay object contains the image,the bounds-image and a reference to the map.
            overlay = new USGSOverlay(bounds, pathfile_srcImage, map);
            // overlay.setOpacity(10);
            // var opacityDisplay
            // opacityDisplay.addEventListener('load', opacitycontrol.opacity);

            //---------------------------------------------------

            enddateForec_value = moment(startdateForec.value).add(4, 'days').format('YYYYMMDD');
            // console.log("enddateForec_value="+enddateForec_value);

            pathfile_srcDataForec_tevol = path_srcDataForecTevol+"/"+regionvalue+"/datasets_marisa/"+varFolder+"/"+regionvalue+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varFile+".nc"
            // console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

            doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile);

            //---------------------------------------------------

            pathfile_srcDataForec_distr = path_srcDataForecDistr+"/"+regionvalue+"/"+variablevalue+"/"+regionvalue+"_p"+startdateForec.value+"_"+enddateForec_value+"_"+varDistrFile_forec+".nc"
            console.log("pathfile_srcDataForec_distr="+pathfile_srcDataForec_distr);

            doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec);

            //---------------------------------------------------
            //======================
            // Create Listeners
            //======================

            // Create Listeners Index-page
            document.getElementById('goLocation').addEventListener('click', function() {
                geocodeLatLng(geocoder, map, infowindow);
            });


            // Create Google Map Event listener when the marker1 is dragend
            google.maps.event.addListener(marker1,'dragend',function() {

                doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile);
                doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec);

            });
            //---------------------------------------------------

        });  //getNetcdf_timestep(pathfile_srcDataForec_tevol

    });  //getJSON(urlJSON_forec


    var dropdown_monthlyClim = document.getElementById('map_monthlyClim');
    // console.log("dropdown_monthlyClim="+dropdown_monthlyClim)
    dropdown_monthlyClim.length = 0;
    dropdown_monthlyClim.selectedIndex = 0;
    const urlJSON_reana = path_srcDataReanaDistr+"/"+regionvalue+"/monthlyClimat/checkDataDistr_reana.json"
    // console.log("urlJSON_reana="+urlJSON_reana);

    // Load Reana Data from JSON file.
    getJSON(urlJSON_reana, function(err, data) {

        if (err != null) {
            console.error(err);
        } else {
            // console.log("data.length="+data.length);
            for (let i = 0; i < data.length; i++) {
                // console.log("data="+data[i].variable);
                if(data[i].variable == varDistrFile_reana){
                    // console.log(data[i].items.length)
                    for (let j = 0; j < data[i].items.length; j++) {
                        option = document.createElement('option');
                        // console.log(data[i].items[j].month)
                        option.text = data[i].items[j].month;
                        //option.value = data[j].month;
                        dropdown_monthlyClim.add(option);
                    }
                    break
                }
            }
        }

        monthlyClim = document.getElementById('map_monthlyClim');
        console.log("monthlyClim="+monthlyClim.value);

        idxMonthForec = Number(startdateForec.value.substring(4,6))
        console.log("idxMonthForec="+idxMonthForec)
        monthlyClim.selectedIndex = idxMonthForec-1

        if(variablevalue == "tempSea" || variablevalue == "curSpeed" || variablevalue == "swh" || variablevalue == "period" ){
            startdateReana_value = '2007'+ leftPad(idxMonthForec, 2)
            enddateReana_value   = '2017'+ leftPad(idxMonthForec, 2)
        } else {
            startdateReana_value = '2018'+ leftPad(idxMonthForec, 2)
            enddateReana_value   = '2018'+ leftPad(idxMonthForec, 2)
        }

        // pathfile_srcDataReana_distr = path_srcDataReanaDistr+"/"+regionvalue+"/monthlyClimat/"+variablevalue+"/"+regionvalue+"_month"+monthlyClim.value.substring(0,3)+"_"+varDistrFile_reana+".nc"
        pathfile_srcDataReana_distr = path_srcDataReanaDistr+"/"+regionvalue+"/monthlyClimat/"+variablevalue+"/"+regionvalue+"_month"+startdateReana_value+"_"+enddateReana_value+"_"+varDistrFile_reana+".nc"
        console.log("pathfile_srcDataReana_distr="+pathfile_srcDataReana_distr);

        doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana);

        //---------------------------------------------------
        //======================
        // Create Listeners
        //======================

        // Create Listeners Index-page
        document.getElementById('goLocation').addEventListener('click', function() {
              geocodeLatLng(geocoder, map, infowindow);
        });

        // Create Google Map Event listener when the marker1 is dragend
        google.maps.event.addListener(marker1, 'dragend', function() {
            doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana);
        });

    });  //getJSON(urlJSON_reana

    // Create Google Map Event listener to change markerColor when the buttom is clicked
    var markerColor = document.getElementById('changeColor');
    google.maps.event.addDomListener(markerColor,"click",function() {

        // pathfile_srcDataReana_distr_swh = path_srcDataReanaDistr+"/"+regionvalue+"/monthlyClimat/swh/"+regionvalue+"_month"+monthlyClim.value.substring(0,3)+"_WaveHeightDistrib.nc"
        pathfile_srcDataReana_distr_swh = path_srcDataReanaDistr+"/"+regionvalue+"/monthlyClimat/swh/"+regionvalue+"_month"+startdateReana_value+"_"+enddateReana_value+"_WaveHeightDistrib.nc"
        pathfile_srcDataForec_distr_swh = path_srcDataForecDistr+"/"+regionvalue+"/swh/"+regionvalue+"_p"+startdateForec.value+"_"+enddateForec_value+"_WaveHeightDistrib.nc"
        console.log("pathfile_srcDataReana_distr_swh="+pathfile_srcDataReana_distr_swh);
        console.log("pathfile_srcDataForec_distr_swh="+pathfile_srcDataForec_distr_swh);
        varDistrFile_reana_swh = "WaveHeightDistribHisto"
        varDistrFile_forec_swh = "WaveHeightDistrib"
        survivalfunction_weibull(pathfile_srcDataReana_distr_swh,pathfile_srcDataForec_distr_swh,varDistrFile_reana_swh,varDistrFile_forec_swh)
    });

}

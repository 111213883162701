import $ from 'jquery';
import { NetCDFReader } from 'netcdfjs';
import { calcIdxVar3d } from '../utils/utils.js';

// Ensure the chart area is empty before rendering a new chart
function clearChartArea() {
    $(document).ready(() => {
        $("#flot-line-chart-multi1").empty();
    });
}

function getDensOptions() {
    return {
        series: {
            bars: { show: true }
        },
        bars: {
            align: "center",
            barWidth: 1.0
        },
        xaxis: {
            position: 'bottom',
            axisLabel: '',
            font: { size: 18, style: "italic", color: "black" }
        },
        yaxes: [{
            position: 'left',
            axisLabel: 'Number of events (norm.)',
            font: { size: 18, style: "italic", color: "black" },
            tickDecimals: 1
        }],
        legend: {
            show: true,
            noColumns: 1,
            margin: 10,
            fontSize: 20,
            labelBoxBorderColor: "black",
            position: "ne"
        },
        grid: {
            hoverable: true,
            borderWidth: 2,
            borderColor: "black",
            backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        tooltip: true,
        tooltipOpts: {
            position: 'absolute',
            display: 'none',
            border: '1px solid #633200',
            padding: '3px',
            'font-size': '19px',
            'border-radius': '6px',
            'background-color': '#fff',
            'font-family': 'Verdana, Arial, Helvetica, Tahoma, sans-serif',
            opacity: 0.9
        }
    };
}

function initializeDensData() {
    return [
        {
            color: "red",
            yaxis: 1
        }
    ];
}

function fetchNetCDFData(url) {
    return new Promise((resolve, reject) => {
        const oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "blob";

        oReq.onload = () => {
            if (oReq.status !== 200) {
                reject(`Error ${oReq.status}: ${oReq.statusText}`);
            } else {
                const reader_url = new FileReader();
                reader_url.onload = function () {
                    resolve(new NetCDFReader(this.result));
                };
                reader_url.readAsArrayBuffer(oReq.response);
            }
        };

        oReq.onerror = () => reject('Network Error');
        oReq.send();
    });
}

function processNetCDFData(reader, varFile, marker1, pathfile_srcDataForec_distr) {
    const lat1d = reader.getDataVariable('latitude');
    const lon1d = reader.getDataVariable('longitude');
    const interv = reader.getDataVariable('elevation');

    const variables = reader.header.variables;
    const field4d = reader.getDataVariable(varFile);

    const { field4d_longName, field4d_units, field4d_fillValue } = extractVariableAttributes(variables, varFile);

    const idxVar = Array(interv.length);
    calcIdxVar3d(field4d, interv, lat1d, lon1d, idxVar, marker1);

    const field1d = calculateField1d(field4d, idxVar, interv, field4d_fillValue);
    const { dateRange, x_mean, max_field } = calculateStatistics(field1d, interv, pathfile_srcDataForec_distr);

    return { field1d, field4d_longName, field4d_units, dateRange, x_mean, max_field, interv };
}

function extractVariableAttributes(variables, varFile) {
    let field4d_longName = '';
    let field4d_units = '';
    let field4d_fillValue = '';

    variables.forEach(variable => {
        if (variable.name === varFile) {
            variable.attributes.forEach(attr => {
                if (attr.name === "long_name") field4d_longName = attr.value;
                if (attr.name === "_FillValue") field4d_fillValue = attr.value;
            });
        }
        if (variable.name === "elevation") {
            variable.attributes.forEach(attr => {
                if (attr.name === "units") field4d_units = attr.value;
            });
        }
    });

    return { field4d_longName, field4d_units, field4d_fillValue };
}


function calculateField1d(field4d, idxVar, interv, fillValue) {
    const dinterv = interv[1] - interv[0];
    const sum = idxVar.reduce((acc, idx) => acc + field4d[idx], 0);

    return idxVar.map((idx, i) => {
        return field4d[idx] === fillValue ? [interv[i], undefined] : [interv[i], field4d[idx] / (sum * dinterv)];
    });
}

function calculateStatistics(field1d, interv, pathfile_srcDataForec_distr) {
    const strSplit = pathfile_srcDataForec_distr.split("_");
    const date0 = strSplit[strSplit.length - 3].replace('p', '');
    const date1 = strSplit[strSplit.length - 2];
    const dateRange = `from ${date0} to ${date1}`;

    let sum_field = 0;
    let max_field = 0;
    let sum_intfield = 0;

    field1d.forEach(([x, y]) => {
        if (y !== undefined) {
            max_field = Math.max(max_field, y);
            sum_field += y;
            sum_intfield += x * y;
        }
    });

    const x_mean = sum_intfield / sum_field;

    return { dateRange, x_mean, max_field };
}

function updateChartOptions(densOptions, field4d_longName, field4d_units, x_mean, max_field, interv) {
    densOptions.bars.barWidth = interv[1] - interv[0];
    densOptions.xaxis.axisLabel = `${field4d_longName} [${field4d_units}]`;
    densOptions.tooltipOpts.content = `<center> %y <br> %x.2 [${field4d_units}] </center>`;
    densOptions.grid.markings = [{
        xaxis: { from: x_mean, to: x_mean },
        yaxis: { from: 0, to: max_field },
        color: "#00ff00"
    }];
}

function updateChartData(densData, field1d, field4d_longName, field4d_units, dateRange) {
    densData[0].data = field1d;
    densData[0].label = `<center>${field4d_longName} <br> (${dateRange})</center>`;
}

function plotChart(densData, densOptions) {
    // $(document).ready(() => {
    //     $.plot($("#flot-line-chart-multi1"), densData, densOptions);
    // });
    const plotContainer = $("#flot-line-chart-multi1");
    const parentWidth = plotContainer.parent().width(); // Get the parent div's width
    plotContainer.width(parentWidth); // Set the plot container's width to match the parent
    $.plot(plotContainer, densData, densOptions);
}



export function doChart_forec_densDistrib(pathfile_srcDataForec_distr,varFile,marker1) {

    clearChartArea();

    const densOptions = getDensOptions();
    const densData = initializeDensData();

    fetchNetCDFData(pathfile_srcDataForec_distr)
        .then(reader => processNetCDFData(reader, varFile, marker1, pathfile_srcDataForec_distr))
        .then(({ field1d, field4d_longName, field4d_units, dateRange, x_mean, max_field, interv }) => {
            updateChartOptions(densOptions, field4d_longName, field4d_units, x_mean, max_field, interv);
            updateChartData(densData, field1d, field4d_longName, field4d_units, dateRange);
            plotChart(densData, densOptions);
        })
        .catch(error => {
            console.error('Error processing NetCDF data:', error);
            plotChart(densData, densOptions); // Plot empty chart in case of error
        });

    // Replot the chart when the window is resized to adapt to the new width
    $(window).resize(function () {
        plotChart(densData, densOptions);
    });
    
    
    // var date1d  = new Array();
    // var field1d = new Array();
    // var freq1d  = new Array();

    // //create a new XMLHttpRequest object
    // let oReq = new XMLHttpRequest();

    // //specify what HTTP request method to use
    // oReq.open("GET", pathfile_srcDataForec_distr,true);

    // //set the type of response we are expecting (blob for binary data)
    // oReq.responseType = "blob";

    // // Send the request over the network
    // oReq.send(); //start process

    // //this is run when the response has returned
    // oReq.onload = function () {
    //     // console.log('Inside the onload event');
    //     if (oReq.status != 200) { // analyze HTTP status of the response
    //         console.log('Error '+oReq.status+': '+oReq.statusText); // e.g. 404: Not Found indica che il server non è riuscito a trovare la risorsa richiesta
    //         $(document).ready(function () {
    //             $.plot($("#flot-line-chart-multi1"), densData, densOptions);
    //         });
    //     } else { // show the result
    //     // if(oReq.readyState === XMLHttpRequest.DONE && oReq.status === 200) {
    //         var blob = oReq.response;

    //         let reader_url = new FileReader();

    //         reader_url.onload = function() {
    //             var t0 = performance.now();

    //             // reader = new netcdfjs(this.result);
    //             const reader = new NetCDFReader(this.result);
    //             //console.log(reader);

    //             var lat1d  = reader.getDataVariable('latitude');
    //             var lon1d  = reader.getDataVariable('longitude');
    //             var interv = reader.getDataVariable('elevation');
    //             //console.log("interv="+interv);

    //             var variables = reader.header.variables;
    //             //console.log(variables);

    //             //-------------------------
    //             //Define Variable and his units
    //             //-------------------------
    //             var field4d = reader.getDataVariable(varFile); // go to offset and read it
    //             //console.log(field4d);
    //             let field4d_longName = '';
    //             let field4d_units = '';
    //             let field4d_fillValue = '';

    //             var idx = 0;
    //             do {
    //                 //console.log(variables[idx].name)
    //                 if(variables[idx].name == varFile){
    //                     //console.log("variables["+idx+"].name="+variables[idx].name);
    //                     var attributes  = variables[idx].attributes;
    //                     // console.log(attributes);
    //                     var idx_var = 0;
    //                     do {
    //                         if(attributes[idx_var].name == "long_name"){
    //                             field4d_longName = attributes[idx_var].value;
    //                             // console.log("field4d_longName="+field4d_longName);
    //                         }
    //                         if(attributes[idx_var].name == "_FillValue"){
    //                             field4d_fillValue  = attributes[idx_var].value;
    //                             // console.log("field4d_fillValue="+field4d_fillValue);
    //                         }
    //                         idx_var += 1;
    //                     } while (idx_var < attributes.length);
    //                 }
    //                 if(variables[idx].name == "elevation"){
    //                     //console.log("variables["+idx+"].name="+variables[idx].name);
    //                     var attributes  = variables[idx].attributes;
    //                     // console.log(attributes);
    //                     var idx_var = 0;
    //                     do {
    //                         if(attributes[idx_var].name == "units"){
    //                             field4d_units  = attributes[idx_var].value;
    //                             // console.log("field4d_units="+field4d_units);
    //                         }
    //                         idx_var += 1;
    //                     } while (idx_var < attributes.length);
    //                 }
    //                 idx += 1;
    //             } while (idx < variables.length);
                
    //             //console.log("field4d_longName="+field4d_longName);
    //             //console.log("field4d_units="+field4d_units);
    //             //console.log("field4d_fillValue="+field4d_fillValue);

    //             var t1 = performance.now();
    //             //console.log("Decoding took " + (t1 - t0) + " milliseconds.");
            
    //             //var field1d = new Array();
    //             // var idxVar  = Array(interv.length);
    //             var idxVar = new Array(interv.length);
    //             calcIdxVar3d(field4d,interv,lat1d,lon1d,idxVar,marker1);
    //             //console.log("idxVar="+idxVar);

    //             var sum = 0;
    //             var dinterv = interv[1] - interv[0]
    //             for(let i = 0; i < idxVar.length; i++) {sum += field4d[idxVar[i]]}
    //             // console.log("sum="+sum)

    //             for (let i = 0; i < idxVar.length; i++){
    //                 if (field4d[idxVar[i]] == field4d_fillValue) {
    //                     field1d[i] = [interv[i],undefined];
    //                 }else {
    //                     // field1d[i] = [interv[i],field4d[idxVar[i]]];
    //                     field1d[i] = [interv[i],field4d[idxVar[i]]/(sum*dinterv)];
    //                 }
    //             }
    //             //console.log(field1d); 

    //             let strSplit = pathfile_srcDataForec_distr.split("_");
    //             let date0 = strSplit[strSplit.length-3].replace('p','');
    //             let date1 = strSplit[strSplit.length-2];
    //             // xyLabel = field4d_longName.replace('distribution','').replace('(Tp)','').replace('(Hm0)','');
    //             let xyLabel = field4d_longName.replace('(Tp)','').replace('(Hm0)','');

    //             var sum_field = 0;
    //             var max_field = 0;
    //             var sum_intfield = 0;
    //             for(let i = 0; i < interv.length; i++){
    //                 max_field = Math.max(max_field,field1d[i][1])
    //                 sum_field += field1d[i][1]
    //                 sum_intfield += interv[i]*field1d[i][1]
    //                 // console.log("field1d[i][1]="+field1d[i][1]+"   max_field="+max_field)
    //             }
    //             let x_mean = sum_intfield/sum_field
    //             // console.log("x_mean="+x_mean)

    //             densData[0].data  = field1d;
    //             densData[0].label = "<center> "+xyLabel+" <br> (from "+date0+" to "+date1+") </center>";
    //             densOptions.bars.barWidth = interv[1] - interv[0];
    //             densOptions.xaxis.axisLabel = xyLabel+" ["+field4d_units+"]";

    //             // showTooltip(0, 0,"<center> %y <br> %x.2 ["+field4d_units+"] </center>");
    //             densOptions.tooltipOpts.content="<center> %y <br> %x.2 ["+field4d_units+"] </center>";

    //             densOptions.grid.markings = [{
    //                     xaxis: { from: x_mean, to: x_mean}, 
    //                     yaxis: { from: 0, to: max_field}, color: "#00ff00"
    //                 }
    //             ]


    //             $(document).ready(function () {
    //                 $.plot($("#flot-line-chart-multi1"), densData, densOptions);
    //             });

    //             // for (let i = 0; i < idxVar.length; i++){ idxVar.length[i] = {}; }
    //         }
    //         //console.log(blob);
    //         reader_url.readAsArrayBuffer(blob);

    //     }

    // };

    // oReq.onerror = function() { // only triggers if the request couldn't be made at all
    //     alert(`Network Error`);
    // };


}

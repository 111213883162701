import $ from 'jquery';
import moment from 'moment';
import Slider from 'bootstrap-slider';
import USGSOverlay from './USGSOverlay.js';
import { doChart_forec_timeSeries } from './flot_tseries.js';
import { doChart_forec_densDistrib } from './flot_dens_forec.js';
import { doChart_reana_densDistrib } from './flot_dens_reana.js';
import { leftPad, getJSON, getNetcdf_timestep } from '../utils/utils.js';

// Global variable definition
var path_srcDataForecTevol = 'products/products_forec_tevol';
var pathfile_srcImage;
var pathfile_srcLabBar;
let overlay;
let bounds;
let map;

let varFolder, varFile, varDistrFile_forec, varDistrFile_reana;

export function change_variable(monthlyClim_value,startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue) {

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        // console.log("overlay"+overlay);
        overlay.setMap(null);
    }

    if(variablevalue=="swh")      { varFolder="swh",      varFile="WaveHeight",       varDistrFile_forec="WaveHeightDistrib",       varDistrFile_reana="WaveHeightDistribHisto"}
    if(variablevalue=="period")   { varFolder="period",   varFile="WavePeriod",       varDistrFile_forec="WavePeriodDistrib",       varDistrFile_reana="WavePeriodDistribHisto"}
    if(variablevalue=="curSpeed") { varFolder="curSpeed", varFile="WaterCurrentSpeed",varDistrFile_forec="WaterCurrentSpeedDistrib",varDistrFile_reana="WaterCurrentSpeedDistribHisto"}
    if(variablevalue=="tempSea")  { varFolder="tempSea",  varFile="WaterTemperature", varDistrFile_forec="WaterTemperatureDistrib", varDistrFile_reana="WaterTemperatureDistribHisto"}
    if(variablevalue=="windSpeed"){ varFolder="windSpeed",varFile="WindCurrentSpeed", varDistrFile_forec="WindCurrentSpeedDistrib", varDistrFile_reana="WindCurrentSpeedDistribHisto"}
    if(variablevalue=="tempAir")  { varFolder="tempAir",  varFile="AirTemperature",   varDistrFile_forec="AirTemperatureDistrib",   varDistrFile_reana="AirTemperatureDistribHisto"}
    if(variablevalue=="mslp")     { varFolder="mslp",     varFile="SeaLevelPressure", varDistrFile_forec="SeaLevelPressureDistrib", varDistrFile_reana="SeaLevelPressureDistribHisto"}
    if(variablevalue=="vis")      { varFolder="vis",      varFile="Visibility",       varDistrFile_forec="VisibilityDistrib",       varDistrFile_reana="VisibilityDistribHisto"}
    if(variablevalue=="tcc")      { varFolder="tcc",      varFile="CloudCover",       varDistrFile_forec="CloudCoverDistrib",       varDistrFile_reana="CloudCoverDistribHisto"}
    if(variablevalue=="prec")     { varFolder="prec",     varFile="Precipitation",    varDistrFile_forec="PrecipitationDistrib",    varDistrFile_reana="PrecipitationDistribHisto"}

    console.log("startdateForec_value="+startdateForec_value);
    console.log("regionvalue="+regionvalue);
    console.log("variablevalue="+variablevalue);

    if(variablevalue == "tempSea" || variablevalue == "curSpeed" ){
        pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec_value+"/"+varFile+"xy_z"+leftPad(depthvalue, 3)+"_t" + leftPad(timevalue, 3)+'.png'
    } else {
        pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec_value+"/"+varFile+"xy_t" + leftPad(timevalue, 3)+'.png'
    }
    pathfile_srcLabBar  = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec_value+"/labelbar.png"
    console.log("pathfile_srcImage="+pathfile_srcImage)
    console.log("pathfile_srcLabBar="+pathfile_srcLabBar)

    document.getElementById('labelbar').src = pathfile_srcLabBar;

    overlay = new USGSOverlay(google.maps, bounds, pathfile_srcImage, map);

    //---------------------------------------------------

    let enddateForec_value = moment(startdateForec_value).add(4, 'days').format('YYYYMMDD');
    console.log("enddateForec_value="+enddateForec_value);

    let pathfile_srcDataForec_tevol = path_srcDataForecTevol+"/"+regionvalue+"/datasets_marisa/"+varFolder+"/"+regionvalue+"_p"+startdateForec_value+"_"+enddateForec_value+"_"+varFile+".nc"
    console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

    var dropdown_time = document.getElementById('map_time');
    dropdown_time.length = 0;

    let nfreq;
    if(variablevalue == "tempSea" || variablevalue == "curSpeed" || variablevalue == "swh" || variablevalue == "period" ){
        nfreq = 24
    } else {
        nfreq = 4
    }

    // Load Data from NETCDF file.
    getNetcdf_timestep(pathfile_srcDataForec_tevol, function(err, data) {

        if (err != null) {
            console.error(err);
        } else {
            //console.log(data);
            for (let i = 0; i < data.length; i++) {
                let option = document.createElement('option');
                option.text = data[i];
                option.value = i;
                dropdown_time.add(option);
            }
        }
        //remove the old slider      
        $(".slider").remove(); 

        let idx = 0;
        let ticksLab = new Array(5);
        for (let i=0;i<data.length;i++){
            if (i == idx*nfreq){
                ticksLab[idx] = data[idx*nfreq].substr(0,16);
                // console.log("ticksLab[idx]="+ticksLab[idx]);
                idx = idx+=1;
            } 
        }
        // console.log(ticksLab)
        var slider = new Slider("#map_slide", {
            ticks: [0, (1*nfreq), (2*nfreq), (3*nfreq), (4*nfreq), (5*nfreq)],
            ticks_labels: [ticksLab[0], ticksLab[1], ticksLab[2], ticksLab[3], ticksLab[4],],
            ticks_positions: [0, 24/1.07, 48/1.07, 72/1.07, 96/1.07, 107/1.07],
            step: 1,
            value: 0,

            formatter: function(value) {
                return '+' + value;
            }
        });

    });
    console.log("dropdown_time="+dropdown_time.value);

    doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile);

    //---------------------------------------------------
    
    let pathfile_srcDataForec_distr = PATH_SRC_DATA_FOREC_DISTR+"/"+regionvalue+"/"+variablevalue+"/"+regionvalue+"_p"+startdateForec_value+"_"+enddateForec_value+"_"+varDistrFile_forec+".nc"       
    console.log(pathfile_srcDataForec_distr);

    doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec);

    //---------------------------------------------------

    var dropdown_monthlyClim = document.getElementById('map_monthlyClim');
    // console.log("dropdown_monthlyClim="+dropdown_monthlyClim)
    dropdown_monthlyClim.length = 0;
    dropdown_monthlyClim.selectedIndex = 0;
    const urlJSON_reana = PATH_SRC_DATA_REANA_DISTR+"/"+regionvalue+"/monthlyClimat/checkDataDistr_reana.json"
    // console.log("urlJSON_reana="+urlJSON_reana);

    // Load Reana Data from JSON file.
    getJSON(urlJSON_reana, function(err, data) {
        
        if (err != null) {
            console.error(err);
        } else {
            // console.log("data.length="+data.length);
            for (let i = 0; i < data.length; i++) {
                // console.log("data="+data[i].variable);
                if(data[i].variable == varDistrFile_reana){
                    // console.log(data[i].items.length)
                    for (let j = 0; j < data[i].items.length; j++) {
                        let option = document.createElement('option');
                        // console.log(data[i].items[j].month)
                        option.text = data[i].items[j].month;
                        //option.value = data[j].month;
                        dropdown_monthlyClim.add(option);
                    }
                    break
                }
            }
        }

        const monthlyClim = document.getElementById('map_monthlyClim');
        console.log("monthlyClim="+monthlyClim.value);
        
        const idxMonthForec = Number(startdateForec_value.substring(4,6))
        console.log("idxMonthForec="+idxMonthForec)
        monthlyClim.selectedIndex = idxMonthForec-1
    
        let startdateReana_value, enddateReana_value;

        if(variablevalue == "tempSea" || variablevalue == "curSpeed" || variablevalue == "swh" || variablevalue == "period" ){
            startdateReana_value = '2007'+ leftPad(idxMonthForec, 2)
            enddateReana_value   = '2017'+ leftPad(idxMonthForec, 2)
        } else {
            startdateReana_value = '2018'+ leftPad(idxMonthForec, 2)
            enddateReana_value   = '2018'+ leftPad(idxMonthForec, 2)
        }
        
        let pathfile_srcDataReana_distr = PATH_SRC_DATA_REANA_DISTR+"/"+regionvalue+"/monthlyClimat/"+variablevalue+"/"+regionvalue+"_month"+startdateReana_value+"_"+enddateReana_value+"_"+varDistrFile_reana+".nc"
        // console.log("pathfile_srcDataReana_distr="+pathfile_srcDataReana_distr);

        doChart_reana_densDistrib(pathfile_srcDataReana_distr,varDistrFile_reana);

    });  //getJSON(urlJSON_reana

}

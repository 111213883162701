// USGSOverlay.js
export default class USGSOverlay {
    constructor(googleMaps, bounds, image, map) {
        this.googleMaps = googleMaps;
        console.log("USGSOverlay initialized with bounds:", bounds);
        this.bounds_ = bounds;
        this.image_ = image;
        this.map_ = map;
        this.div_ = null;
        this.overlayView = new googleMaps.OverlayView();
        this.overlayView.onAdd = this.onAdd.bind(this);
        this.overlayView.draw = this.draw.bind(this);
        this.overlayView.onRemove = this.onRemove.bind(this);
        this.overlayView.setMap(map);
        console.log("USGSOverlay instance created and set on map.");
    }

    onAdd() {
        var div = document.createElement('div');
        div.className = 'reset';
        div.style.borderStyle = 'none';
        div.style.borderWidth = '0px';
        div.style.position = 'absolute';
        div.style.opacity = "0.8";

        var img = document.createElement('img');
        img.src = this.image_;
        img.style.width = '100%';
        img.style.height = '100%';
        img.style.position = 'absolute';
        div.appendChild(img);

        this.div_ = div;

        var panes = this.overlayView.getPanes();
        panes.overlayLayer.appendChild(div);
    }

    draw() {
        var overlayProjection = this.overlayView.getProjection();
        var sw = overlayProjection.fromLatLngToDivPixel(this.bounds_.getSouthWest());
        var ne = overlayProjection.fromLatLngToDivPixel(this.bounds_.getNorthEast());

        var div = this.div_;
        div.style.left = sw.x + 'px';
        div.style.top = ne.y + 'px';
        div.style.width = (ne.x - sw.x) + 'px';
        div.style.height = (sw.y - ne.y) + 'px';
    }

    onRemove() {
        this.div_.parentNode.removeChild(this.div_);
        this.div_ = null;
    }

    hide() {
        if (this.div_) {
            this.div_.style.visibility = 'hidden';
        }
    }

    show() {
        if (this.div_) {
            this.div_.style.visibility = 'visible';
        }
    }

    toggle() {
        if (this.div_) {
            if (this.div_.style.visibility === 'hidden') {
                this.show();
            } else {
                this.hide();
            }
        }
    }

    toggleDOM() {
        if (this.overlayView.getMap()) {
            this.overlayView.setMap(null);
        } else {
            this.overlayView.setMap(this.map_);
        }
    }
}
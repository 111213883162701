// Import necessary modules
import USGSOverlay from './USGSOverlay.js';
import { leftPad } from '../utils/utils.js';

// Global variable definition
var path_srcDataForecTevol = 'products/products_forec_tevol';
var pathfile_srcImage;
var pathfile_srcLabBar;
let overlay, bounds, map;
let varFolder, varFile, varDistrFile_forec, varDistrFile_reana;

// Function to construct image paths based on parameters
function constructImagePath(region, varFolder, startdate, varFile, time, depth = null) {
    const depthPart = depth !== null ? `z${leftPad(depth, 3)}_` : '';
    return `${path_srcDataForecTevol}/${region}/figures/${varFolder}/${startdate}/${varFile}xy_${depthPart}t${leftPad(time, 3)}.png`;
    // if(variablevalue == "tempSea" || variablevalue == "curSpeed" ){
    //     pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec_value+"/"+varFile+"xy_z"+leftPad(depthvalue, 3)+"_t" + leftPad(timevalue, 3)+'.png'
    // } else {
    //     pathfile_srcImage = path_srcDataForecTevol+"/"+regionvalue+"/figures/"+varFolder+"/"+startdateForec_value+"/"+varFile+"xy_t" + leftPad(timevalue, 3)+'.png'
    // }
}


/**
 * Updates the overlay on the map with a new image path.
 * @param {string} imagePath - Path to the new image to display.
 */
function updateOverlay(imagePath) {
    // Check if overlay is an instance of USGSOverlay before calling setMap
    if (overlay instanceof USGSOverlay && typeof overlay.setMap === 'function') {
        overlay.setMap(null);
    } else {
        console.warn("Overlay is not correctly initialized or is not an instance of USGSOverlay.");
    }

    // Debugging: Check if map and bounds are properly initialized
    if (!map || !bounds) {
        console.error("Map or bounds are not initialized. Map:", map, "Bounds:", bounds);
        return;
    }

    // Create a new USGSOverlay instance
    overlay = new USGSOverlay(google.maps, bounds, imagePath, map);

    // Check if the new overlay instance is correctly initialized
    if (overlay instanceof USGSOverlay && typeof overlay.setMap === 'function') {
        overlay.setMap(map);
    } else {
        console.error("Failed to initialize USGSOverlay or setMap is not a function.");
    }
}

/**
 * Assigns folder and file names based on the selected variable.
 * @param {string} variable - The variable to display.
 */
function assignVariableDetails(variable) {
    const variables = {
        swh: ["swh", "WaveHeight", "WaveHeightDistrib", "WaveHeightDistribHisto"],
        period: ["period", "WavePeriod", "WavePeriodDistrib", "WavePeriodDistribHisto"],
        curSpeed: ["curSpeed", "WaterCurrentSpeed", "WaterCurrentSpeedDistrib", "WaterCurrentSpeedDistribHisto"],
        tempSea: ["tempSea", "WaterTemperature", "WaterTemperatureDistrib", "WaterTemperatureDistribHisto"],
        windSpeed: ["windSpeed", "WindCurrentSpeed", "WindCurrentSpeedDistrib", "WindCurrentSpeedDistribHisto"],
        tempAir: ["tempAir", "AirTemperature", "AirTemperatureDistrib", "AirTemperatureDistribHisto"],
        mslp: ["mslp", "SeaLevelPressure", "SeaLevelPressureDistrib", "SeaLevelPressureDistribHisto"],
        vis: ["vis", "Visibility", "VisibilityDistrib", "VisibilityDistribHisto"],
        tcc: ["tcc", "CloudCover", "CloudCoverDistrib", "CloudCoverDistribHisto"],
        prec: ["prec", "Precipitation", "PrecipitationDistrib", "PrecipitationDistribHisto"],
    };
    
    const variableDetails = variables[variable];
    
    if (!variableDetails) {
        throw new Error(`Unknown variable: ${variable}`);
    }

    [varFolder, varFile, varDistrFile_forec, varDistrFile_reana] = variableDetails;
}

/**
 * Updates the forecast visualization based on the selected parameters.
 * @param {string} startdateForec_value - Forecast start date.
 * @param {number} timevalue - Selected time step.
 * @param {string} regionvalue - Region selected for the forecast.
 * @param {string} variablevalue - The variable to display (e.g., 'swh', 'tempSea').
 * @param {number} depthvalue - Selected depth (for certain variables).
 */
export function change_timeStepForec(startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue) {

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        overlay.setMap(null);
    }

    assignVariableDetails(variablevalue);

    const pathfile_srcImage = constructImagePath(regionvalue, varFolder, startdateForec_value, varFile, timevalue, depthvalue);
    const pathfile_srcLabBar = `${path_srcDataForecTevol}/${regionvalue}/figures/${varFolder}/${startdateForec_value}/labelbar.png`;

    console.log("Image Path:", pathfile_srcImage);
    console.log("Label Bar Path:", pathfile_srcLabBar);

    document.getElementById('labelbar').src = pathfile_srcLabBar;

    // overlay = new USGSOverlay(google.maps, bounds, pathfile_srcImage, map);
    updateOverlay(pathfile_srcImage);
    //---------------------------------------------------

    const enddateForec_value = moment(startdateForec_value).add(4, 'days').format('YYYYMMDD');
    console.log("enddateForec_value="+enddateForec_value);

    const pathfile_srcDataForec_tevol = path_srcDataForecTevol+"/"+regionvalue+"/datasets_marisa/"+varFolder+"/"+regionvalue+"_p"+startdateForec_value+"_"+enddateForec_value+"_"+varFile+".nc"
    console.log("pathfile_srcDataForec_tevol="+pathfile_srcDataForec_tevol);

    doChart_forec_timeSeries(pathfile_srcDataForec_tevol,varFile);

    //---------------------------------------------------

    const pathfile_srcDataForec_distr = path_srcDataForecDistr+"/"+regionvalue+"/"+variablevalue+"/"+regionvalue+"_p"+startdateForec_value+"_"+enddateForec_value+"_"+varDistrFile_forec+".nc"
    console.log(pathfile_srcDataForec_distr);

    doChart_forec_densDistrib(pathfile_srcDataForec_distr,varDistrFile_forec);

}





export function change_timeStepForec_next(startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue){

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        overlay.setMap(null);
    }

    assignVariableDetails(variablevalue);

    console.log("startdateForec_value="+startdateForec_value);
    console.log("regionvalue="+regionvalue);
    console.log("variablevalue="+variablevalue);

    timevalue = Math.min(timevalue + 1, 107);

    document.getElementById("map_time").selectedIndex = timevalue;
    document.getElementById('map_slide').value = timevalue;
    
    const pathfile_srcImage = constructImagePath(regionvalue, varFolder, startdateForec_value, varFile, timevalue, depthvalue);
    console.log("pathfile_srcImage="+pathfile_srcImage)
    // console.log("pathfile_srcLabBar="+pathfile_srcLabBar);

    updateOverlay(pathfile_srcImage);
    // overlay = new USGSOverlay(google.maps, bounds, pathfile_srcImage, map); 
}

/**
 * Updates the overlay to show the previous time step.
 */
export function change_timeStepForec_preview(startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue){

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        overlay.setMap(null);
    }

    assignVariableDetails(variablevalue);

    timevalue = Math.max(timevalue - 1, 0); // Prevent time step from going below 0
    
    document.getElementById("map_time").selectedIndex = timevalue; 
    document.getElementById('map_slide').value = timevalue; 
    //console.log('type='+typeOf(timevalue)+' time_next='+timevalue);
    
    const pathfile_srcImage = constructImagePath(regionvalue, varFolder, startdateForec_value, varFile, timevalue, depthvalue);
    console.log("Previous Image Path:", pathfile_srcImage);

    updateOverlay(pathfile_srcImage);
}

/**
 * Updates the overlay based on the slider input.
 */
export function change_timeStepForec_slide(startdateForec_value,timevalue,regionvalue,variablevalue,depthvalue) {

    // Ensure overlay is properly initialized before nullifying it
    if (overlay && typeof overlay.setMap === 'function') {
        overlay.setMap(null);
    }

    assignVariableDetails(variablevalue);

    console.log('varFolder:', varFolder);
    console.log('varFile:', varFile);
    console.log('startdateForec_value:', startdateForec_value);
    console.log('pathfile_srcLabBar:', pathfile_srcLabBar);

    //console.log('type='+typeOf(timevalue)+' timevalue='+timevalue);
    const slidevalue = document.getElementById('map_slide').value;
    console.log(' slidevalue='+slidevalue);

    // Update the time selected index based on the slider value
    document.getElementById("map_time").selectedIndex = slidevalue; 

    const pathfile_srcImage = constructImagePath(regionvalue, varFolder, startdateForec_value, varFile, slidevalue, depthvalue);
    pathfile_srcLabBar = `${path_srcDataForecTevol}/${regionvalue}/figures/${varFolder}/${startdateForec_value}/labelbar.png`;

    console.log("Slider Image Path:", pathfile_srcImage);
    console.log("Label Bar Path:", pathfile_srcLabBar);

    // Update the label bar image in the DOM
    document.getElementById('labelbar').src = pathfile_srcLabBar;

    updateOverlay(pathfile_srcImage);
}

import $ from 'jquery';
import { NetCDFReader } from 'netcdfjs';
import { calcIdxVar2d, calcIdxVar3d } from '../utils/utils.js';


function range(start, end, step = 1) {
  const len = Math.floor((end - start) / step) + 1
  return Array(len).fill().map((_, idx) => start + (idx * step))
}

// Exponential Weibull PDF
function expweibPDF(x, alpha, kappa, lambda) {
    
    var y_weibull = new Array();
    for (let i = 0; i < x.length; i++) { 
        let a = alpha*(kappa/lambda)*Math.pow(x[i]/lambda,kappa-1) 
        let b = Math.pow(1 - Math.exp(-Math.pow(x[i]/lambda,kappa)),alpha-1)
        let c = Math.exp(-Math.pow(x[i]/lambda, kappa))
        y_weibull[i] = a * b * c
        // console.log("x="+x[i]+" alpha="+alpha+" kappa="+kappa+" lambda="+lambda+"  y_weibull="+y_weibull[i])
    }

    return y_weibull
    // return (alpha * (k/lam) *((x/lam)**(k-1)) * ((1 - exp(-(x/lam)**k))**(alpha-1)) * exp(-(x/lam)**k))
}



// Ensure the chart area is empty before rendering a new chart
function clearChartArea() {
    $(document).ready(() => {
        $("#flot-line-chart-multi2").empty();
    });
}

// Helper function to initialize densOptions
function getDensOptions() {
    return {
        bars: {
            align: "center",
            barWidth: 1.0
        },
        xaxis: {
            position: 'bottom',
            axisLabel: '',
            font: { size: 18, style: "italic", color: "black" }
        },
        yaxes: [{
            position: 'left',
            axisLabel: 'Number of events (norm.)',
            font: { size: 18, style: "italic", color: "black" },
            tickDecimals: 1
        }],
        legend: {
            show: true,
            noColumns: 1,
            margin: 10,
            fontSize: 20,
            labelBoxBorderColor: "black",
            position: "ne"
        },
        grid: {
            hoverable: true,
            borderWidth: 2,
            borderColor: "black",
            backgroundColor: { colors: ["#ffffff", "#EDF5FF"] }
        },
        tooltip: true,
        tooltipOpts: {
            position: 'absolute',
            display: 'none',
            top: 0,
            left: 0,
            border: '1px solid #633200',
            padding: '3px',
            'font-size': '19px',
            'border-radius': '6px',
            'background-color': '#fff',
            'font-family': 'Verdana, Arial, Helvetica, Tahoma, sans-serif',
            opacity: 0.9
        }
    };
}

// Helper function to initialize densData
function initializeDensData() {
    return [
        {
            color: "black",
            yaxis: 1,
            bars: { show: true }
        },
        {
            color: "blue",
            yaxis: 1,
            lines: { show: true }
        }
    ];
}

function fetchNetCDFData(url) {
    return new Promise((resolve, reject) => {
        const oReq = new XMLHttpRequest();
        oReq.open("GET", url, true);
        oReq.responseType = "blob";

        oReq.onload = () => {
            if (oReq.status !== 200) {
                reject(`Error ${oReq.status}: ${oReq.statusText}`);
            } else {
                const reader_url = new FileReader();
                reader_url.onload = function () {
                    resolve(new NetCDFReader(this.result));
                };
                reader_url.readAsArrayBuffer(oReq.response);
            }
        };

        oReq.onerror = () => reject('Network Error');
        oReq.send();
    });
}



function processNetCDFData(reader, varFile, marker1, monthlyClim, pathfile_srcDataReana_distr) {
    const lat1d = reader.getDataVariable('latitude');
    const lon1d = reader.getDataVariable('longitude');
    const interv = reader.getDataVariable('elevation');
    const field4d = reader.getDataVariable(varFile);

    const { field4d_longName, field4d_units, field4d_fillValue, alpha, kappa, loc, lambda } = extractAttributes(reader, varFile, marker1, lat1d, lon1d);

    const idxVar = Array(interv.length);
    calcIdxVar3d(field4d, interv, lat1d, lon1d, idxVar, marker1);

    const field1d = calculateField1d(field4d, idxVar, interv, field4d_fillValue);
    const field1d_weibull = calculateWeibullDistribution(alpha, kappa, lambda, interv);

    const yearRange = extractYearRange(pathfile_srcDataReana_distr);
    const month0 = monthlyClim.value;

    return { field1d, field1d_weibull, field4d_longName, field4d_units, alpha, kappa, lambda, interv, yearRange, month0 };
}



function extractAttributes(reader, varFile, marker1, lat1d, lon1d) {
    const variables = reader.header.variables;

    let field4d_longName = '';
    let field4d_units = '';
    let field4d_fillValue = '';
    let alpha, kappa, loc, lambda;

    variables.forEach(variable => {
        const attributes = variable.attributes;

        if (variable.name === varFile) {
            attributes.forEach(attr => {
                if (attr.name === "long_name") field4d_longName = attr.value;
                if (attr.name === "_FillValue") field4d_fillValue = attr.value;
            });
        }

        if (variable.name === "elevation") {
            attributes.forEach(attr => {
                if (attr.name === "units") field4d_units = attr.value;
            });
        }

        if (variable.name === "WaveHeight_alpha") {
            alpha = extractWeibullParameter(reader, 'WaveHeight_alpha', attributes, marker1, lat1d, lon1d);
        }
        if (variable.name === "WaveHeight_kappa") {
            kappa = extractWeibullParameter(reader, 'WaveHeight_kappa', attributes, marker1, lat1d, lon1d);
        }
        if (variable.name === "WaveHeight_loc") {
            loc = extractWeibullParameter(reader, 'WaveHeight_loc', attributes, marker1, lat1d, lon1d);
        }
        if (variable.name === "WaveHeight_lambda") {
            lambda = extractWeibullParameter(reader, 'WaveHeight_lambda', attributes, marker1, lat1d, lon1d);
        }
    });

    return { field4d_longName, field4d_units, field4d_fillValue, alpha, kappa, loc, lambda };
}


function extractWeibullParameter(reader, varName, attributes, marker1, lat1d, lon1d) {
    const paramData = reader.getDataVariable(varName);
    let fillValue;

    attributes.forEach(attr => {
        if (attr.name === "_FillValue") fillValue = attr.value;
    });

    const idxVar = Array(1);
    calcIdxVar2d(paramData, lat1d, lon1d, idxVar, marker1);
    return paramData[idxVar] === fillValue ? undefined : paramData[idxVar];
}

function calculateField1d(field4d, idxVar, interv, fillValue) {
    const dinterv = interv[1] - interv[0];
    const sum = idxVar.reduce((acc, idx) => acc + field4d[idx], 0);

    return idxVar.map((idx, i) => {
        return field4d[idx] === fillValue ? [interv[i], undefined] : [interv[i], field4d[idx] / (sum * dinterv)];
    });
}

function calculateWeibullDistribution(alpha, kappa, lambda, interv) {
    if (!alpha || !kappa || !lambda) return [];

    const interv_weibull = range(interv[0], 4.0, 0.01);
    const dinterv_weibull = interv_weibull[1] - interv_weibull[0];
    const y_weibull = expweibPDF(interv_weibull, alpha, kappa, lambda);

    return interv_weibull.map((x, i) => [x, y_weibull[i] * dinterv_weibull]);
}

function extractYearRange(path) {
    const strSplit = path.split("_");
    const year000 = strSplit[strSplit.length - 3].replace('month', '');
    const yearend = strSplit[strSplit.length - 2];
    return `${year000} to ${yearend}`;
}

function updateDensData(densData, field1d, field1d_weibull, field4d_longName, field4d_units, alpha, kappa, lambda, month0, yearRange) {
    densData[0].data = field1d;
    densData[0].label = `<center>${field4d_longName} <br> (${month0} from ${yearRange})</center>`;

    if (alpha && kappa && lambda) {
        densData[1].data = field1d_weibull;
        densData[1].label = `<center>Weibull parameters: (kappa=${kappa.toFixed(2)}, lambda=${lambda.toFixed(2)})</center>`;
    }
}

function updateDensOptions(densOptions, field4d_longName, field4d_units, interv, variable, swh_threshold) {
    densOptions.bars.barWidth = interv[1] - interv[0];
    densOptions.xaxis.axisLabel = `${field4d_longName} [${field4d_units}]`;
    densOptions.tooltipOpts.content = `<center> %y.2 <br> %x.2 [${field4d_units}] </center>`;

    if (variable.value === "swh") {
        densOptions.grid.markings = [
            { xaxis: { from: 0, to: swh_threshold.value }, color: "#eeeeff" },
            { xaxis: { from: swh_threshold.value, to: 4 }, color: "#eeffee" }
        ];
    }
}


// Function to plot data
function plotData(densData, densOptions) {

    // var data = [
    //     { label: "Series 1", data: [[0, 1], [1, 5], [2, 3], [3, 7], [4, 9], [5, 4]] },
    //     { label: "Series 2", data: [[0, 3], [1, 2], [2, 5], [3, 6], [4, 2], [5, 8]] }
    // ];

    // var options = {
    //     series: {
    //         lines: { show: true },
    //         points: { show: true }
    //     },
    //     grid: {
    //         hoverable: true,
    //         clickable: true
    //     },
    //     yaxis: {
    //         min: 0,
    //         max: 10
    //     },
    //     xaxis: {
    //         min: 0,
    //         max: 5
    //     }
    // };

    // var plot = $.plot($("#flot-line-chart-multi2"), data, options);

    // $(document).ready(function () {
    //     $.plot($("#flot-line-chart-multi2"), densData, densOptions);
    // });

    // Ensure the plot container's width matches its parent
    const plotContainer = $("#flot-line-chart-multi2");
    const parentWidth = plotContainer.parent().width(); // Get the parent div's width
    plotContainer.width(parentWidth); // Set the plot container's width to match the parent
    
    // Plot the data using Flot
    $.plot(plotContainer, densData, densOptions);

}




export function doChart_reana_densDistrib(pathfile_srcDataReana_distr,varFile,monthlyClim,variable,swh_threshold,marker1) {

    clearChartArea();

    const densOptions = getDensOptions();
    const densData = initializeDensData();

    fetchNetCDFData(pathfile_srcDataReana_distr)
        .then(reader => processNetCDFData(reader, varFile, marker1, monthlyClim, pathfile_srcDataReana_distr))
        .then(data => {
            const { field1d, field1d_weibull, field4d_longName, field4d_units, alpha, kappa, lambda, interv, yearRange, month0 } = data;

            updateDensData(densData, field1d, field1d_weibull, field4d_longName, field4d_units, alpha, kappa, lambda, month0, yearRange);
            updateDensOptions(densOptions, field4d_longName, field4d_units, interv, variable, swh_threshold);
            plotData(densData, densOptions);
        })
        .catch(error => {
            console.error('Error processing NetCDF data:', error);
            plotData(densData, densOptions); // Plot empty chart in case of error
        });

    // Replot the chart when the window is resized to adapt to the new width
    $(window).resize(function () {
        plotData(densData, densOptions);
    });


    // const date1d = [];
    // const field1d = [];
    // const field1d_weibull = [];
    // const freq1d = [];

    // //create a new XMLHttpRequest object
    // let oReq = new XMLHttpRequest();
    // //specify what HTTP request method to use
    // oReq.open("GET", pathfile_srcDataReana_distr,true);
    // //set the type of response we are expecting (blob for binary data)
    // oReq.responseType = "blob";
    // // Send the request over the network
    // oReq.send();

    // //this is run when the response has returned
    // oReq.onload = function () {
    //     if (oReq.status !== 200) {
    //         console.error(`Error ${oReq.status}: ${oReq.statusText}`);
    //         plotData(densData, densOptions);
    //         return;
    //     }

    //     const reader_url = new FileReader();
    //     reader_url.onload = function () {
    //         const t0 = performance.now();

    //         const reader = new NetCDFReader(this.result);
    //         //console.log(reader);

    //         const lat1d  = reader.getDataVariable('latitude');
    //         const lon1d  = reader.getDataVariable('longitude');
    //         const interv = reader.getDataVariable('elevation');
    //         //console.log("interv="+interv);

    //         const variables = reader.header.variables;
    //         var field4d = reader.getDataVariable(varFile); // go to offset and read it
    //         const idxVar = new Array(interv.length);
    //         // console.log("idxVar="+idxVar);
    //         calcIdxVar3d(field4d,interv,lat1d,lon1d,idxVar,marker1);

    //         //console.log(field4d);
    //         let field4d_longName = '';
    //         let field4d_units = '';
    //         let field4d_fillValue = '';
    //         let alpha2d_longName = '';
    //         let alpha2d_fillValue = '';
    //         let kappa2d_longName = '';
    //         let kappa2d_fillValue = '';
    //         let loc2d_longName = '';
    //         let loc2d_fillValue = '';
    //         let lambda2d_longName = '';
    //         let lambda2d_fillValue = '';

    //         let alpha, kappa, loc, lambda;


    //         var idx = 0;
    //         do {
    //             if(variables[idx].name == varFile){
    //                 //console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "long_name"){
    //                         field4d_longName = attributes[idx_var].value;
    //                         // console.log("field4d_longName="+field4d_longName);
    //                     }
    //                     if(attributes[idx_var].name == "_FillValue"){
    //                         field4d_fillValue  = attributes[idx_var].value;
    //                         // console.log("field4d_fillValue="+field4d_fillValue);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);

    //                 let sum = 0;
    //                 var dinterv = interv[1] - interv[0]
    //                 for(let i = 0; i < idxVar.length; i++) {sum += field4d[idxVar[i]]}
    //                 // console.log("sum="+sum)

    //                 for (let i = 0; i < idxVar.length; i++){
    //                     if (field4d[idxVar[i]] == field4d_fillValue) {
    //                         field1d[i] = [interv[i],undefined];
    //                     }else {
    //                         field1d[i] = [interv[i],field4d[idxVar[i]]/(sum*dinterv)];
    //                     }
    //                 }
    //             }
    //             if(variables[idx].name == "WaveHeight_alpha"){
    //                 // console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var alpha2d = reader.getDataVariable('WaveHeight_alpha');

    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "long_name"){
    //                         alpha2d_longName = attributes[idx_var].value;
    //                         // console.log("alpha2d_longName="+alpha2d_longName);
    //                     }
    //                     if(attributes[idx_var].name == "_FillValue"){
    //                         alpha2d_fillValue  = attributes[idx_var].value;
    //                         // console.log("alpha2d_fillValue="+alpha2d_fillValue);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);

    //                 var idxVar_alpha = Array(1);
    //                 calcIdxVar2d(alpha2d,lat1d,lon1d,idxVar_alpha,marker1);
    //                 if (alpha2d[idxVar_alpha] == alpha2d_fillValue) {
    //                     alpha = undefined;
    //                 }else {
    //                     alpha = alpha2d[idxVar_alpha];
    //                 }
    //                 // console.log(alpha);
    //             }
    //             if(variables[idx].name == "WaveHeight_kappa"){
    //                 // console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var kappa2d = reader.getDataVariable('WaveHeight_kappa');

    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "long_name"){
    //                         kappa2d_longName = attributes[idx_var].value;
    //                         // console.log("kappa2d_longName="+kappa2d_longName);
    //                     }
    //                     if(attributes[idx_var].name == "_FillValue"){
    //                         kappa2d_fillValue  = attributes[idx_var].value;
    //                         // console.log("kappa2d_fillValue="+kappa2d_fillValue);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);

    //                 var idxVar_kappa = Array(1);
    //                 calcIdxVar2d(kappa2d,lat1d,lon1d,idxVar_kappa,marker1);
    //                 if (kappa2d[idxVar_kappa] == kappa2d_fillValue) {
    //                     kappa = undefined;
    //                 }else {
    //                     kappa = kappa2d[idxVar_kappa];
    //                 }
    //                 // console.log(kappa);
    //             }
    //             if(variables[idx].name == "WaveHeight_loc"){
    //                 // console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var loc2d = reader.getDataVariable('WaveHeight_loc');

    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "long_name"){
    //                         loc2d_longName = attributes[idx_var].value;
    //                         // console.log("loc2d_longName="+loc2d_longName);
    //                     }
    //                     if(attributes[idx_var].name == "_FillValue"){
    //                         loc2d_fillValue  = attributes[idx_var].value;
    //                         // console.log("loc2d_fillValue="+loc2d_fillValue);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);

    //                 var idxVar_loc = Array(1);
    //                 calcIdxVar2d(loc2d,lat1d,lon1d,idxVar_loc,marker1);
    //                 if (loc2d[idxVar_loc] == loc2d_fillValue) {
    //                     loc = undefined;
    //                 }else {
    //                     loc = loc2d[idxVar_loc];
    //                 }
    //                 // console.log(loc);
    //             }
    //             if(variables[idx].name == "WaveHeight_lambda"){
    //                 // console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var lambda2d = reader.getDataVariable('WaveHeight_lambda');

    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "long_name"){
    //                         lambda2d_longName = attributes[idx_var].value;
    //                         // console.log("lambda2d_longName="+lambda2d_longName);
    //                     }
    //                     if(attributes[idx_var].name == "_FillValue"){
    //                         lambda2d_fillValue  = attributes[idx_var].value;
    //                         // console.log("lambda2d_fillValue="+lambda2d_fillValue);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);

    //                 var idxVar_lambda = Array(1);
    //                 calcIdxVar2d(lambda2d,lat1d,lon1d,idxVar_lambda,marker1);
    //                 if (lambda2d[idxVar_lambda] == lambda2d_fillValue) {
    //                     lambda = undefined;
    //                 }else {
    //                     lambda = lambda2d[idxVar_lambda];
    //                 }
    //                 // console.log(lambda);
    //             }
    //             if(variables[idx].name == "elevation"){
    //                 //console.log("variables["+idx+"].name="+variables[idx].name);
    //                 var attributes  = variables[idx].attributes;
    //                 // console.log(attributes);
    //                 var idx_var = 0;
    //                 do {
    //                     if(attributes[idx_var].name == "units"){
    //                         field4d_units  = attributes[idx_var].value;
    //                         //console.log("field4d_units="+field4d_units);
    //                     }
    //                     idx_var += 1;
    //                 } while (idx_var < attributes.length);
    //             }
    //             idx += 1;
    //         } while (idx < variables.length);
            
    //         //console.log("field4d_longName="+field4d_longName);
    //         //console.log("field4d_units="+field4d_units);
    //         //console.log("field4d_fillValue="+field4d_fillValue);

    //         var t1 = performance.now();
    //         //console.log("Decoding took " + (t1 - t0) + " milliseconds.");

    //         let strSplit = pathfile_srcDataReana_distr.split("_");
    //         let year000 = strSplit[strSplit.length-3].replace('month','');
    //         let yearend = strSplit[strSplit.length-2];
    //         let month0 = monthlyClim.value;
    //         // xyLabel = field4d_longName.replace('distribution','').replace('(Tp)','').replace('(Hm0)','');
    //         let xyLabel = field4d_longName.replace('(Tp)','').replace('(Hm0)','');

    //         if (alpha && kappa && lambda) {
    //             var interv_weibull = range(interv[0], 4.0, 0.01);
    //             var dinterv_weibull = interv_weibull[1] - interv_weibull[0]
    //             let y_weibull = expweibPDF(interv_weibull, alpha, kappa, lambda)

    //             var sum_weibull = 0;
    //             for(let i = 0; i < interv_weibull.length; i++) {sum_weibull += y_weibull[i]}
    //             // console.log("sum_weibull="+sum_weibull)

    //             for (let i = 0; i < interv_weibull.length; i++){
    //                 // field1d_weibull[i] = [interv_weibull[i],y_weibull[i]];
    //                 field1d_weibull[i] = [interv_weibull[i],(y_weibull[i]*sum_weibull*dinterv_weibull)];
    //                 // console.log("field1d_weibull="+interv_weibull[i]+","+field1d_weibull[i][1]);
    //             }

    //             densData[1].data  = field1d_weibull;
    //             if(!isNaN(kappa)){
    //                 densData[1].label = "<center>"+" weibull parameters:(kappa="+kappa.toFixed(2)+", lambda="+lambda.toFixed(2)+") </center>";
    //             }

    //         }

    //         densData[0].data  = field1d;
    //         densData[0].label = "<center>"+xyLabel+" <br> ("+month0+" from "+year000+" to "+yearend+") </center>";

    //         densOptions.bars.barWidth = interv[1] - interv[0];
    //         densOptions.xaxis.axisLabel = xyLabel+" ["+field4d_units+"]";
    //         densOptions.tooltipOpts.content="<center> %y.2 <br> %x.2 ["+field4d_units+"] </center>";

    //         if(variable.value == "swh"){
    //             densOptions.grid.markings = [
    //                 {xaxis: {from: 0, to: swh_threshold.value}, color: "#eeeeff"},
    //                 {xaxis: {from: swh_threshold.value, to: 4}, color: "#eeffee"}
    //             ]
    //         }

    //         plotData(densData, densOptions);

    //     }
    //     //console.log(blob);
    //     reader_url.readAsArrayBuffer(oReq.response);

    // };

    // oReq.onerror = function() { // only triggers if the request couldn't be made at all
    //     alert(`Network Error`);
    // };

    // // Replot the chart when the window is resized to adapt to the new width
    // $(window).resize(function () {
    //     plotData(densData, densOptions);
    // });
    
}
